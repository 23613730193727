/*
 * Author: bjiang
 * Create Time: 2020/1/10 9:33
 */

import QueryString from "querystring";
import ApiError from "./ApiError";
import { after, before, CONFIG, filter, setOptions } from "./config";
import { mergeHeaders, stringify } from "./utils";

/**
 * 补充headers信息
 * @param {HttpOptions}config 输入配置项
 * @returns {HttpOptions} 合并后的配置项
 */
const appendHeader = (config) => {
  return Object.assign({}, config, {
    headers: mergeHeaders({
      "Pragma": "no-cache",
      "Cache-Control": "no-cache",
      "Content-Type": "application/json",
      "Accept": "application/json",
      "User-Token": CONFIG.getToken()
    }, CONFIG.headers, config.headers || {})
  });
};

/**
 * 超时计时器
 * @param {number}timeout 超时时间
 * @returns {Promise} 超时Promise
 */
const timerPromise = (timeout) => new Promise((resolve, reject) => {
  setTimeout(
    () => reject({
      status: "TIMEOUT",
      json: () => new ApiError("系统超时", "TIMEOUT", [])
    }),
    timeout
  );
});

/**
 * 执行请求
 * @param {string}action 请求地址
 * @param {*}params 入参
 * @param {"GET"|"POST"|"DELETE"|"PUT"}method 请求方式
 * @param {HttpOptions}orgConfig http配置项
 * @returns {Promise<Response>} //
 */
const request = (action, params, method, orgConfig) => {
  const config = appendHeader(orgConfig);
  if (["GET", "HEAD"].indexOf(method) !== -1 && Object.keys(params).length > 0) {
    action = `${action}?${QueryString.stringify(params)}`;
  }
  if (["GET", "HEAD"].indexOf(method) !== -1) params = undefined;
  // 前置拦截，仅在拦截器return false时阻断请求执行。
  return filter.before.reduce((pool, fn) => pool.then(async (init) => {
    if (init === null || init === false) throw new Error("前置拦截器阻断");
    const result = await fn(init);
    return result !== undefined ? result : init;
  }), Promise.resolve({
    // 仅在Content-Type为json时，才执行JSON序列化工作。
    body: config.headers["Content-Type"] === "application/json" ? JSON.stringify(params) : params,
    method,
    mode: "same-origin",
    credentials: "same-origin",
    ...config
  })).then(init => fetch(action, init));
};
const ERROR_TITLE = {
  400: "请求参数异常",
  401: "登录状态失效",
  404: "缺少服务",
  500: "系统异常"
};
/**
 * 调用API发送请求，并整理响应结果
 * @param {string}action
 * @param {object}params
 * @param {string}method
 * @param {HttpOptions}config
 * @returns {Promise<Promise | Response>}
 */
const invokeApi = (action, params = {}, method = "GET", config = {}) => {
  const { timeout = 60000 } = config;
  const iterable = [
    timerPromise(timeout),
    request(action, params, method, config)
  ];
  return Promise.race(iterable)
    .then(res => (res.status >= 200 && res.status < 300)
      ? res.json().catch(() => ({})).then(data => Object.defineProperty(data, "__response__", { value: res }))
      : Promise.reject(res))
    .catch((res) => res.json().catch(() => ({}))
      .then(err => filter.after.reduce(
        (pool, fn) => pool.then(async ([status, data]) => {
          if (data === null) return [status, data]; // return null 表示终止后续拦截器
          const result = await fn(status, data);
          return result !== undefined ? [status, result] : [status, data];
        }),
        Promise.resolve([res.status, err])
      )).then((error) => {
        // 系统其他标准异常直接抛出
        if (error instanceof Error) throw error;
        const [status, data] = error;
        // 仅在data已经被处理成null时，不再报出异常信息
        if (data === null) return;
        // 特殊处理，401状态下需要清理用户信息
        if (status === 401) CONFIG.clearUser();
        // 特殊处理，401状态下，可以由基础配置以及请求配置共同合并进行请求请试。
        if (status === 401 && CONFIG.retry && config.retry) return CONFIG.retry().then(() => invokeApi(action, params, method, config));
        throw new ApiError(ERROR_TITLE[status] || "未知类型异常", status, data);
      }));
};

export default {
  get (url, param, options) {
    return invokeApi(url, param, "GET", options);
  },
  post (url, param, options) {
    return invokeApi(url, param, "POST", options);
  },
  delete (url, param, options) {
    return invokeApi(url, param, "DELETE", options);
  },
  put (url, param, options) {
    return invokeApi(url, param, "PUT", options);
  }
};

export { stringify, setOptions, after, before };
