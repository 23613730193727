/*
 * Author: bjiang
 * Create Time: 2019/10/22 08:56
 */

import { TITLE_POP, TITLE_PUSH } from "../constant";

/**
 * 添加页头
 * @param {string}title
 * @returns {{type: string, title: *}}
 */
export const push = (title) => ({ type: TITLE_PUSH, title });

/**
 * 弹出页头
 * @returns {{type: string}}
 */
export const pop = () => ({ type: TITLE_POP });
