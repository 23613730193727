import { Formik } from "formik";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import { Redirect, withRouter } from "react-router-dom";
import { withRouterPropsTypes } from "../../../helpers/commPropTypes";
import {
  getLastDailyCollectionInfoRecordByEmployeeNumberByDateV2,
  submitEmployeeDailyCollectionInfoV2
} from "../../../services/interface/tleave/collection";
import Loading from "../../../ui/loading";
import Modal from "../../../ui/Modal";
import { Provider } from "../context";
import defaultValues from "../defaultValues";
import merge from "../utils/merge";
import { checkSubmit } from "../utils/validation";
import VALIDATION_PARAM from "./check";
import applySubmit from "../../../business/Apply/util/applySubmit";

// 用S替换地址详情中的-
// const MATCH = "<";
const propTypes = {
  history: withRouterPropsTypes.history,
  location: withRouterPropsTypes.location,
  getInfo: PropTypes.func,
  putInfo: PropTypes.func,
  children: PropTypes.node
};
const defaultProps = {
  getInfo: getLastDailyCollectionInfoRecordByEmployeeNumberByDateV2,
  putInfo: submitEmployeeDailyCollectionInfoV2
};

/**
 * @description 表单信息
 */
class BaseQuestion extends React.Component {
  /**
   * @constructor
   * @param {propTypes} props
   */
  constructor (props) {
    super(props);
    this.state = {
      date: this.props.location.state?.date,
      initialValues: null,
      // 提交按钮状态
      submitIsAllow: false
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSureSubmit = this.handleSureSubmit.bind(this);
  }

  /**
   * 请求当前员工信息
   */
  doSearch () {
    if (!this.state.date) return;
    this.props.getInfo({ dateTo: moment(this.state.date).format("yyyy-MM-DD") }).catch(() => ({}))
      .then(({ data } = { data: {} }) => {
        // 接口返回为 YYYY-MM-DD HH:MM:SSTtimeZoom 转换为moment日期对象
        ["backToShanghaiScheduleDate", "leaveShanghaiScheduleDate", "specialFocusStartDate"].forEach((key) => {
          if (data[key]) {
            data[key] = moment(data[key]).format("YYYY-MM-DD");
          }
        });
        // 剩余可提交时间， > 0 为可操作时间， <= 0 为已过期时间
        const { leftSubmitAvailableMillisecond = "0" } = data;
        const leftFillOutTime = parseInt(leftSubmitAvailableMillisecond);
        // data.employeeCurrentPosCity = data.employeeCurrentPosCity.replace(/</g, "-");
        const initialValues = merge({}, defaultValues, data);
        if (leftFillOutTime > 0) {
          setTimeout(() => {
            this.setState({ submitIsAllow: false });
          }, leftFillOutTime);
        }
        this.setState({
          initialValues,
          submitIsAllow: leftFillOutTime > 0
        });
      });
  }

  /**
   * @description 填写验证
   * @param {object} values 表单数据
   */
  handleSubmit (values) {
    applySubmit(VALIDATION_PARAM, values, this.handleSureSubmit);
  }

  /**
   * @description 提交表单
   * @param {*}values
   * @returns {void}
   */
  handleSureSubmit (values) {
    const { putInfo, history } = this.props;
    delete values.crtDate;
    /**
     * @modify 2020/05/20 mjchen
     * 取消本人或周边人员当天是否在生病
     * 该字段值同本人或周边人员目前是否有发热、咳嗽、乏力、呕吐等症状
     * */
    values.ifSelfOrAroundIllnessToday = values.ifFeverOrCough;
    checkSubmit(values).then(() => {
      const loading = Loading.show();
      putInfo(values).finally(() => loading.hide()).then(() => {
        Modal.alert({
          content: "您提交的信息已更新！",
          onOk: () => history.push("../report")
        });
      }).catch(err => {
        // 提取接口报错文案，若未返回则给出默认文案
        let msg = "提交失败，请检查信息是否正确后重新再试！";
        if (err.apiErrors) {
          const { message } = err.apiErrors;
          msg = message;
        } else if (Object.prototype.toString.call(err) === "[object Error]") {
          msg = err.message;
        }
        Modal.alert({ theme: "warning", content: msg });
      });
    }).catch(err => history.push(`./${err.message}`));
  }

  /**
   * @description 生命周期函数
   */
  componentDidMount () {
    // 调用当前员工信息获取接口
    this.doSearch();
  }

  /**
   * @description 渲染函数
   * @returns {Comment}
   */
  render () {
    const { children } = this.props;
    const { initialValues, submitIsAllow } = this.state;
    if (!this.state.date) return <Redirect to={"/healthy/report"}/>;
    return initialValues &&
      <Formik initialValues={initialValues} onSubmit={this.handleSubmit} validateOnChange={true}>
        {(formikProps) => <Provider value={{ ...formikProps, submitIsAllow }}>
          <form onSubmit={formikProps.handleSubmit}>
            {children}
          </form>
        </Provider>}
      </Formik>;
  }
}

BaseQuestion.displayName = "BaseQuestion";
BaseQuestion.propTypes = propTypes;
BaseQuestion.defaultProps = defaultProps;

export default withRouter(BaseQuestion);
