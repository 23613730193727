import { combineReducers } from "redux";
import Browser from "src/services/browser/reducer";
import Authority from "src/services/profile/Authority/reducer";
import User from "src/services/profile/User/reducer";

const reducer = combineReducers(Object.assign({},
  Browser,
  User,
  Authority
));

export default reducer;
