/*
  name: 对应form表单中values的对应字段
  type: 对应该问题需要引用的组件
  label: 为问题描述
  optional: 部分问题为下拉框，此为下拉选项
 */
export default [
  {
    name: "illnessInfoIllnessNames",
    type: "input",
    label: "生病人员姓名（关系）",
    options: {
      placeholder: "多个人员斜杠分隔，例：'张三/李四（父亲）'"
    }
  },
  {
    name: "illnessInfoIfIllnessNewOrOld",
    type: "radio",
    label: "生病情况",
    options: [
      { value: "1", label: "当天新病" },
      { value: "0", label: "旧病未愈" }
    ]
  }
];
