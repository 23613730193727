/*
  name: 对应form表单中values的对应字段
  type: 对应该问题需要引用的组件
  label: 为问题描述
  optional: 部分问题为下拉框，此为下拉选项
 */
export default [
  {
    label: "具体说明",
    name: "covid19Remark",
    type: "input",
    options: {
      placeholder: "请备注经过、疑似/感染时间、是否已隔离等"
    }
  }
];
