/*
 * Author: bjiang
 * Create Time: 2020/3/5 17:28
 */

import PropTypes from "prop-types";
import { useEffect } from "react";
import content, { propTypes as browserPropTypes } from "../services/browser";

/**
 * @typedef {object} BrowserProps
 * @property {string} name
 * @property {ReactNode} children
 */

const propTypes = {
  ...browserPropTypes,
  children: PropTypes.node,
  name: PropTypes.string
};

/**
 * @description 浏览器控制组件
 * @param {BrowserProps}props
 * @returns {*|null}
 * @constructor
 */
const Browser = (props) => {
  const { title, name, children } = props;
  useEffect(() => title.push(name) && title.pop, [name]);
  return children || null;
};

Browser.propTypes = propTypes;

export default content(Browser);
