/**
 * Created with WebStorm.
 * User: Tlionsir
 * Date: 2020/6/17
 * Time: 17:25
 * To change this template use File | Settings | File Templates.
 * Describe:
 */
import Modal from "../../../ui/Modal";
import Toast from "../../../ui/Toast";

/**
 * @description 公用提交方法
 * @param {Schema} validation 表单校验配置
 * @param {object} _values 表单数据
 * @param {function} handleSureSubmit 表单校验配置
 */
const applySubmit = (validation, _values, handleSureSubmit) => {
  validation.validate(_values)
    .then((values) => {
      Modal.confirm({
        content: "确认提交数据？",
        type: "confirm",
        onOk: () => handleSureSubmit(values)
      });
    })
    .catch((errors) => {
      let errorMsg = "";
      Object.keys(errors).forEach((item) => {
        if (!errorMsg && errors[item]) {
          errorMsg = errors[item];
          Toast.show({ message: errors[item] });
        }
      });
    });
};
export default applySubmit;
