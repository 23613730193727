import React from "react";
import ReactDom from "react-dom";
import Button from "../Button";
import Modal from "./Modal";
import STYLES from "./style.module.less";

/**
 * @typedef {object} ModalLocal
 * @property {string}[ok] 确定
 * @property {string}[cancel] 取消
 * @property {string}[close] 关闭
 * @property {string}[defaultTitle] 标题
 */

// 如果需要对按钮文本进行国际化改造，需要在外部调用setDefaultLocal来进行设置，不要在此修改。
/**
 * @type {ModalLocal}
 */
const local = {
  ok: "确定",
  cancel: "取消",
  close: "关闭",
  defaultTitle: ""
};

const defaultProps = {
  styles: STYLES
};

/**
 * 设置语言环境
 * @param {ModalLocal} _local 国际化语言配置
 */
export const setDefaultLocal = (_local) => {
  Object.assign(local, _local);
};

/**
 * @description 设置默认样式
 * @param {Object}styles
 */
export const setDefaultStyles = (styles) => {
  Object.assign(defaultProps, { styles });
};

/**
 * 基础弹窗模块
 * @returns {Modal}
 */
const createModal = ({ onClose, styles = defaultProps.styles, ...props }) => {
  const dom = document.createElement("div");
  dom.classList.add(styles["modal-container"]);
  document.body.appendChild(dom);
  return ReactDom.render(
    <Modal {...props} styles={styles}
      local={Object.assign({}, props.local, local)}
      onClose={() => {
        if (!document.body.contains(dom)) return;
        ReactDom.unmountComponentAtNode(dom);
        document.body.removeChild(dom);
        if (onClose) onClose();
      }}
    />, dom);
};

/**
 * @description 创建标准Modal
 * @param {ModalProps} props
 * @returns {Modal}
 */
export const modal = (props) => createModal(props);

/**
 * @typedef {ModalPropsOpt} Alert~ModalPropsOpt
 * @property {function} [onOk]
 */

/**
 * @description 按Alert创建Modal
 * @param {Alert~ModalPropsOpt} props
 * @returns {Modal}
 */
export const alert = (props) => {
  return createModal({
    footer: <Button btnType={"warning"} onClick={props.onOk}>
      {(props.local || {}).ok || local.ok}
    </Button>,
    title: local.defaultTitle,
    ...props,
    type: "alert",
    hasClose: true
  });
};

/**
 * @description 按confirm创建Modal
 * @param {AlertProps}props
 * @returns {Modal}
 */
export const confirm = (props) => {
  return createModal({
    maskOnClose: false,
    footer: [
      <Button key={"cancel"} btnType="secondary" onClick={props.onCancel}>
        {(props.local || {}).cancel || local.cancel}
      </Button>,
      <Button key={"ok"} btnType="warning" onClick={props.onOk}>
        {(props.local || {}).ok || local.ok}
      </Button>
    ],
    title: local.defaultTitle,
    ...props,
    type: "confirm"
  });
};

/**
 * @description 按Popup方式创建Modal
 * @param {ModalProps}props
 * @returns {Modal}
 */
export const popup = (props) => {
  return createModal({
    hasClose: true,
    title: local.defaultTitle,
    ...props,
    type: "popup"
  });
};

export default { alert, confirm, popup, modal, setDefaultLocal };
