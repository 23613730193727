/*
 * Author: bjiang
 * Create Time: 2020/6/29 10:18
 */

import PropTypes from "prop-types";
import { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { withRouterPropsTypes } from "../helpers/commPropTypes";
import connectAuthority, { propTypes as AuthorityTypes } from "../services/profile/Authority";
import connectUser, { propTypes as UserProps } from "../services/profile/User";
import Modal from "../ui/Modal";

const propTypes = {
  children: PropTypes.node,
  ...UserProps,
  ...AuthorityTypes,
  ...withRouterPropsTypes
};
const defaultProps = {};
const { ga, GA_MEASUREMENT_ID } = window;

/**
 * @typedef {Object} ExceptionProps
 * @property {function} clear
 * @property {function} logout
 * @property {Node} children
 */

/**
 * @description 异常拦截
 * @param {ExceptionProps}props
 * @returns {Component|Null}
 * @constructor
 */
const Exception = (props) => {
  const { clear, logout, location: { pathname }, history } = props;
  // 访问日志
  useEffect(() => {
    ga("config", GA_MEASUREMENT_ID, { page_path: pathname, page_title: document.title });
  }, [pathname]);
  // 顶层异常拦截
  useEffect(() => {
    window.addEventListener("unhandledrejection", (event) => {
      if (event === null || event.reason === null) return;
      const { reason } = event;
      if (reason instanceof Error && reason.status) {
        switch (reason.status) {
          case 401: {
            clear();
            logout();
            history.replace("/login");
            break;
          }
        }
      } else if (reason instanceof Error) {
        Modal.alert({ content: reason.message, onOk: undefined });
      }
    });
  }, []);
  const { children } = props;
  return children || null;
};

Exception.displayName = "Exception";
Exception.propTypes = propTypes;
Exception.defaultProps = defaultProps;

export default withRouter(connectUser(connectAuthority(Exception)));
