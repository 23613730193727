import React from "react";
import { Route, Switch } from "react-router-dom";
import loadable from "../business/loadable";
import { withRouterPropsTypes } from "../helpers/commPropTypes";

const List = loadable(() => import(/* webpackChunkName: "Employ/List" */"src/scene/Employ/List"));
const Manager = loadable(() => import(/* webpackChunkName: "Employ/List/Manager" */"src/scene/Employ/List/Manager"));
const Apply = loadable(() => import(/* webpackChunkName: "Employ/Apply" */"src/scene/Employ/Apply"));
const Detail = loadable(() => import(/* webpackChunkName: "Employ/Detail" */"src/scene/Employ/Detail"));
/**
 * @description 录用模块
 * @param {string}path
 * @returns {ReactElement}
 * @constructor
 */
const EmployRouter = ({ match: { path } }) => (
  <Switch>
    {/* 个人列表 */}
    <Route exact path={`${path}`} component={List}/>
    {/* 申请 */}
    <Route exact path={`${path}/apply`} component={Apply}/>
    {/* 管理列表 */}
    <Route exact path={`${path}/manager`} component={Manager}/>
    {/* 管理详情 */}
    <Route path={`${path}/manager/:id`} component={Detail}/>
    {/* 详情 */}
    <Route path={`${path}/:id`} component={Detail}/>
  </Switch>
);

EmployRouter.propTypes = withRouterPropsTypes;

export default EmployRouter;
