/**
 * Create Time 2021/2/7 11:6
 */

import http from "../httpApi";

/**
 * @description 上分登录口
 * @description 上分OA系统登录接口
 * @param {loginInfoDTO} body - 登录
 * @param {HttpOptions}[httpOptions] - 额外的查询配置
 * @returns {Promise<void>} - 200 成功运营 successful operation
 * @returns {Promise<loginInfoResultDTO>} - 201 成功运营 successful operation
 */
export const login = (body, httpOptions) => {
  return http.post("/tleave/mobile/login",
    body, httpOptions);
};

/**
 * @description 上分
 * @description 上分OA系统登出接口
 * @param {HttpOptions}[httpOptions] - 额外的查询配置
 * @returns {Promise<void>} - 200 成功运营 successful operation
 */
export const logout = (httpOptions) => {
  return http.delete("/tleave/mobile/logout",
    {}, httpOptions);
};
