import { applyMiddleware, compose, createStore } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./Reducer"; // 即combineReducers之后的rootReducer

const middleWares = [thunk];

if (process.env.NODE_ENV === "development") {
  const { logger } = require("redux-logger");
  middleWares.push(logger);
}
let store;
if (process.env.NODE_ENV !== "production" && window.__REDUX_DEVTOOLS_EXTENSION__) {
  store = createStore(rootReducer, compose(applyMiddleware(...middleWares), window.__REDUX_DEVTOOLS_EXTENSION__()));
} else {
  store = createStore(rootReducer, applyMiddleware(...middleWares));
}

export { store };
