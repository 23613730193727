/*
 * Author: Tlionsir
 * Create Time: 2020/5/13 11:16
 */

import React from "react";
import { Route, Switch } from "react-router-dom";
import loadable from "../business/loadable";
import { withRouterPropsTypes } from "../helpers/commPropTypes";

const Setting = loadable(() => import(/* webpackChunkName: "Setting" */"src/scene/Setting"));
const Authorize = loadable(() => import(/* webpackChunkName: "Authorize" */"src/scene/Setting/Authorize"));
const AuthorizationManagement = loadable(() => import(/* webpackChunkName: "AuthorizationManagement" */"src/scene/Setting/AuthorizationManagement"));
const AuthorizerList = loadable(() => import(/* webpackChunkName: "AuthorizerList" */"src/scene/Setting/AuthorizerList"));
const Authorizee = loadable(() => import(/* webpackChunkName: "Authorizee" */"src/scene/Setting/Authorizee"));

/**
 * @description 登录
 * @param {string}path
 * @returns {ReactElement}
 * @constructor
 */
const SettingRouter = ({ match: { path } }) => (
  <Switch>
    {/* 设置主页-我的 */}
    <Route exact path={`${path}`} component={Setting}/>
    {/* 授权管理 */}
    <Route exact path={`${path}/authorization-management`} component={AuthorizationManagement}/>
    {/* 我要授权 */}
    <Route exact path={`${path}/authorization-management/authorize`} component={Authorize}/>
    {/* 角色切换 */}
    <Route exact path={`${path}/authorization-management/conversion`} component={Authorizee}/>
    {/* 查看授权 */}
    <Route exact path={`${path}/authorization-management/authorizer-list`} component={AuthorizerList}/>
  </Switch>
);

SettingRouter.propTypes = withRouterPropsTypes;

export default SettingRouter;
