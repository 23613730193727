/*
 * Author: sh-home
 * Create Time: 2020/5/10 18:28
 */
import cx from "classnames";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Fragment, useContext } from "react";
import { withRouter } from "react-router-dom";
import { withRouterPropsTypes } from "src/helpers/commPropTypes/withRouter";
import Button from "src/ui/Button";
import Context from "../context";
import styles from "../styles.module.less";

/**
 * @typedef {withRouterPropsTypes} WarpProps
 * @property {Node} children
 */
const propTypes = {
  children: PropTypes.node,
  ...withRouterPropsTypes
};
const defaultProps = {};
/**
 * @description 基础信息包裹组件
 * @param {WarpProps}props
 * @returns {Component}
 */
const Wrap = (props) => {
  const { children, location } = props;
  const formikProps = useContext(Context);
  const { values: { parseDate, ifRecord, unavailableSubmitNoticeMessage }, submitIsAllow } = formikProps;
  const pass = ifRecord === "1";
  return (
    <Fragment>
      <div className={styles.pass}>
        <span>{moment(parseInt(parseDate)).format("YYYY-MM-DD")}</span>
        <span className={cx({
          [styles.yes]: pass,
          [styles.no]: !pass
        })}> {pass ? "已填写" : "未填写"}</span>
        {typeof unavailableSubmitNoticeMessage === "string" && unavailableSubmitNoticeMessage !== "" &&
        <p>{unavailableSubmitNoticeMessage}</p>}
      </div>
      <section className={!submitIsAllow ? styles.disabled : ""}>
        {children}
      </section>
      {/base-question$/.test(location.pathname) && <div className={styles["button-submit"]}>
        <Button disabled={!submitIsAllow} type={"submit"}>提交</Button>
      </div>}
    </Fragment>
  );
};

Wrap.displayName = "Wrap";
Wrap.propTypes = propTypes;
Wrap.defaultProps = defaultProps;

export default withRouter(Wrap);
