/*
 * Author: Tlionsir
 * Create Time: 2020/5/13 11:16
 */

import React from "react";
import { Route, Switch } from "react-router-dom";
import loadable from "../business/loadable";
import { withRouterPropsTypes } from "../helpers/commPropTypes";

const List = loadable(() => import(/* webpackChunkName: "HomeRouter/List" */"src/scene/Home/List"));
const Detail = loadable(() => import(/* webpackChunkName: "HomeRouter/detail" */"src/scene/Home/Detail"));
/**
 * @description 通知公告
 * @param {string}path
 * @returns {ReactElement}
 * @constructor
 */
const HomeRouter = ({ match: { path } }) => (
  <Switch>
    {/* 公告贴片 */}
    <Route exact path={`${path}`} component={List}/>
    {/* 公告详情 */}
    <Route exact path={`${path}/:id`} component={Detail}/>

  </Switch>
);

HomeRouter.propTypes = withRouterPropsTypes;

export default HomeRouter;
