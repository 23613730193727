import cx from "classnames";
import PropTypes from "prop-types";
import React from "react";
import style from "./icon.module.less";

const propTypes = {
  // 图标的名称
  name: PropTypes.string.isRequired,
  // 尺寸，以12为0，每增加1增大2个字号
  size: PropTypes.number,
  // 颜色属性
  color: PropTypes.oneOf([
    "primary", "warning", "secondary", "info", "success", "light", "dark", "danger",
    "dark-primary", "dark-warning", "dark-secondary", "dark-info", "dark-success", "dark-light", "dark-dark", "dark-danger"
  ]),
  iconfont: PropTypes.string,
  // 附加样式
  className: PropTypes.string,
  // 附加样式
  style: PropTypes.object,
  prefix: PropTypes.string
};
const defaultProps = {
  prefix: "icon",
  iconfont: "iconfont",
  style: {}
};

const Icon = React.forwardRef(({ iconfont, name, size, color, className, style: propsStyle, prefix, ...props }, ref) => {
  const classes = cx(iconfont, className, style.icon, {
    [[prefix, name].join("-")]: !!name,
    [style[color]]: color
  });
  const _style = Object.assign({}, propsStyle,
    size !== undefined && { fontSize: size * 2 + 12 }
  );
  return <i className={classes} {...props} style={_style} ref={ref}/>;
});
Icon.displayName = "Icon";
Icon.propTypes = propTypes;
Icon.defaultProps = defaultProps;

export default Icon;
