/*
 * Author: sh-home
 * Create Time: 2020/4/15 9:25
 */

import { CLEAR, LOGIN, LOGIN_FAIL, LOGIN_START, LOGOUT, UPDATE } from "./constant";

/**
 * @description 退出登录
 * @returns {{type: string}}
 */
export const logout = () => ({ type: LOGOUT });

/**
 * @description 开始登录
 * @returns {{type: string}}
 */
export const loginStart = () => ({ type: LOGIN_START });

/**
 * @description 登录成功
 * @returns {{type: string}}
 */
export const loginSuccess = () => ({ type: LOGIN });

/**
 * @description 登录失败
 * @returns {{type: string}}
 */
export const loginFail = () => ({ type: LOGIN_FAIL });

/**
 * 更新用户信息
 * @param {userInfoDTO} user
 * @returns {{type: string, user: userInfoDTO}}
 */
export const update = (user) => ({ type: UPDATE, user });

/**
 * @description 清理本地数据
 * @returns {{type:string}}
 */
export const clear = () => ({ type: CLEAR });
