import React from "react";
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import AuthorizedRoute from "src/business/AuthorizedRoute";
import AutoLogin from "./business/AutoLogin";
import Exception from "./business/Exception";
import loadable from "./business/loadable";
import { withRouterPropsTypes } from "./helpers/commPropTypes";
import BadgeRouter from "./Router/BadgeRouter";
import BusinessTripRouter from "./Router/BusinessTripRouter";
import CancellationLeave from "./Router/CancellationLeave";
import DynamicsRouter from "./Router/DynamicsRouter";
import EmployRouter from "./Router/EmployRouter";
import HealthyRouter from "./Router/HealthyRouter";
import EpidemicRouter from "./Router/EpidemicRouter";
import HomeRouter from "./Router/HomeRouter";
import HospitalityRouter from "./Router/HospitalityRouter";
import LeaveRouter from "./Router/LeaveRouter";
import OvertimeRouter from "./Router/OvertimeRouter";
import SettingRouter from "./Router/SettingRouter";
import Exp404 from "./scene/404";
import Construction from "./scene/Construction";
import connect, { propTypes as AuthorityTypes } from "./services/profile/Authority";

const Demo = process.env.NODE_ENV !== "production" && loadable(() => import(/* webpackChunkName: "Demo" */"./scene/Demo"));

const Login = loadable(() => import(/* webpackChunkName: "login" */"./scene/login"));
const Forget = loadable(() => import(/* webpackChunkName: "Forget" */"./scene/Forget"));
const Password = loadable(() => import(/* webpackChunkName: "Password" */"./scene/Setting/Password"));
const Entrance = loadable(() => import(/* webpackChunkName: "Entrance" */"./scene/Entrance"));
const UserInfo = loadable(() => import(/* webpackChunkName: "UserInfo" */"./scene/Setting/UserInfo"));
const Update = loadable(() => import(/* webpackChunkName: "Update" */"./scene/Setting/Update"));

const propTypes = {
  ...AuthorityTypes,
  ...withRouterPropsTypes
};
/**
 * @description 根路由
 * @returns {Component}
 * @constructor
 */
const Routers = ({ dynamics }) => (
  <Router>
    <Exception/>
    <AutoLogin/>
    <Switch>
      {process.env.NODE_ENV !== "production" && <Route exact path={"/test"} component={Demo}/>}
      <Route exact path={"/login"} component={Login}/>
      <Route exact path={"/forget"} component={Forget}/>
      <Route exact path={"/reset-password"} component={Password}/>
      <AuthorizedRoute path={"/healthy"} component={HealthyRouter}/>
      <AuthorizedRoute path={"/hospitality"} component={HospitalityRouter}/>
      <AuthorizedRoute path={"/badge"} component={BadgeRouter}/>
      <AuthorizedRoute path={"/overtime"} component={OvertimeRouter}/>
      <AuthorizedRoute path={"/leave"} component={LeaveRouter}/>
      <AuthorizedRoute path={"/employ"} component={EmployRouter}/>
      <AuthorizedRoute path={"/business-trip"} component={BusinessTripRouter}/>
      <AuthorizedRoute path={"/cancellation"} component={CancellationLeave}/>
      <AuthorizedRoute path={"/notices"} component={HomeRouter}/>
      <AuthorizedRoute path={"/epidemic"} component={EpidemicRouter}/>
      <AuthorizedRoute path={"/entrance"} component={Entrance}/>
      <AuthorizedRoute path={"/discover"} component={Construction}/>
      <AuthorizedRoute path={"/setting/message"} component={Construction}/>
      <AuthorizedRoute path={"/setting/telephone"} component={Update}/>
      <AuthorizedRoute path={"/setting/mobile-phone"} component={Update}/>
      <AuthorizedRoute path={"/setting/password"} component={Password}/>
      <AuthorizedRoute path={"/setting/user-info"} component={UserInfo}/>
      <AuthorizedRoute path={"/setting"} component={SettingRouter}/>
      {
        DynamicsRouter(dynamics)
      }
      <Route exact path={"/"} render={() => <Redirect to={"/entrance"}/>}/>
      <Route render={() => <Redirect to={"/entrance"}/>}/>
      <Route component={Exp404}/>
    </Switch>
  </Router>
);

Routers.propTypes = propTypes;
export default connect(Routers);
