/*
 * Author: bjiang
 * Create Time: 2020/5/11 15:19
 */
export default {
  ifSelfOrAroundIllnessToday: "0",
  ifFeverOrCough: "0",
  ifGotoDoctor: "0",
  ifCOVID19: "0",
  attachHubeiRelPeopleSituation: "0",
  ifRecentBackToShanghaiSchedule: "0",
  ifRecentLeaveShanghaiSchedule: "0",
  ifSpecialFocus: "0",
  specialInstructions: "0"
};
