/*
 * Author: Allie
 * Create Time: 2020/9/18
 */
import React from "react";
import { Route } from "react-router-dom";
import loadable from "../business/loadable";

const Apply = loadable(() => import(/* webpackChunkName: "Apply" */"../scene/Stage/Apply"));
const Approve = loadable(() => import(/* webpackChunkName: "Approve" */"../scene/Stage/Approve"));
const Detail = loadable(() => import(/* webpackChunkName: "Detail" */"../scene/Stage/Detail"));
const List = loadable(() => import(/* webpackChunkName: "List" */"../scene/Stage/List"));

/**
 * @description M端微服务路由
 * @param {Array} dynamics
 * @returns {*}
 * @constructor
 */
const DynamicsRouter = (dynamics = []) => (
  dynamics.map(({ processDefineKey, scope = "", url }) => {
    const listPath = `/${processDefineKey}/${scope}`.replace(/\/$/, "");
    const applyPath = `${listPath}/apply`;
    const detailPath = `${listPath}/:id`;
    const approvePath = `${listPath}/:id/approve`;
    return !url && [
      <Route exact key={listPath} path={listPath} component={List}/>,
      <Route exact key={applyPath} path={applyPath} component={Apply}/>,
      <Route exact key={approvePath} path={approvePath} component={Approve}/>,
      <Route exact key={detailPath} path={detailPath} component={Detail}/>
    ];
  })
);

export default DynamicsRouter;
