/*
  name: 对应form表单中values的对应字段
  type: 对应该问题需要引用的组件
  label: 为问题描述
  optional: 部分问题为下拉框，此为下拉选项
 */
export default [
  {
    label: "当天所在地区(地级行政区)",
    name: "employeeCurrentPosCity",
    type: "city-select"
  },
  {
    label: "本人或周边人员目前是否有发热、咳嗽、乏力、呕吐等症状？",
    name: "ifAroundOrSelfFeverOrCough",
    type: "extension",
    options: [
      { value: "0", label: "否" },
      { value: "1", label: "是", linkTo: "illnessInfo" }
    ]
  },
  {
    label: "是否直接或间接接触过疑似或确诊人员？",
    name: "ifAttachCOVID19",
    type: "extension",
    options: [
      { value: "0", label: "否" },
      { value: "1", label: "直接接触", linkTo: "specificInstructions" },
      { value: "2", label: "间接接触", linkTo: "specificInstructions" }
    ]
  },
  {
    label: "是否认定疑似或确诊感染新冠病毒？",
    name: "ifCOVID19",
    type: "extension",
    options: [
      { value: "0", label: "否" },
      { value: "1", label: "确认", linkTo: "confirm" },
      { value: "2", label: "疑似", linkTo: "confirm" }
    ]
  },
  {
    label: "是否被隔离",
    name: "ifSelfIsolate",
    type: "extension",
    options: [
      { value: "0", label: "否" },
      { value: "1", label: "隔离", linkTo: "quarantined" }
    ]
  }
];
