/**
 * Created by chaozhang on 2019/9/27.
 */
import cx from "classnames";
import PropTypes from "prop-types";
import React, { Component, Fragment } from "react";
import ExternalMount from "../ExternalMount";
import { getDefaultLocal } from "./local";
import STYLES from "./styles.module.less";

/**
 * @typedef {object} LoadingProps
 * @property {"static"|"popup"} type static 嵌入模式， popup 弹出模式
 * @property {object} styles
 * @property {string} className
 * @property {boolean} isShow 是否显示
 * @property {boolean} showMask 是否使用背景蒙板
 * @property {string} size 尺寸
 * @property {boolean} horizontal 横向显示图标与文本
 * @property {boolean} external 是否外部添加
 * @property {boolean} showText 是否展示文本
 * @property {{text:string,img:string}} local
 * @property {function} onHidden 关闭事件
 */
// 指定参数类型
const propTypes = {
  /** 类型 **/ // static 嵌入模式， popup 弹出模式
  type: PropTypes.oneOf(["static", "popup"]),
  styles: PropTypes.object,
  className: PropTypes.string,
  // 是否显示
  isShow: PropTypes.bool,
  // 是否使用背景蒙板
  showMask: PropTypes.bool,
  // 尺寸
  size: PropTypes.string,
  // 横向显示图标与文本
  horizontal: PropTypes.bool,
  // 是否外部添加
  external: PropTypes.bool,
  // 展示的文本
  showText: PropTypes.bool,
  // 国际化
  local: PropTypes.shape({
    text: PropTypes.string,
    img: PropTypes.string
  }),
  // 关闭事件
  onHidden: PropTypes.func
};

// 参数添加默认值
const defaultProps = {
  type: "static",
  styles: STYLES,
  className: "",
  isShow: true,
  showMask: false,
  size: "large",
  horizontal: false,
  showText: true,
  external: false,
  onHidden: () => undefined
};

/**
 * Loading组件
 */
class LoadingComponent extends Component {
  /**
   * 生命周期函数，更新时触发
   * @param {LoadingProps} prevProps
   */
  componentDidUpdate (prevProps) {
    if (prevProps.isShow !== this.props.isShow && this.props.isShow === false) {
      this.props.onHidden();
    }
  }

  /**
   * 外部渲染
   * @param {ReactElement}children
   * @returns {ReactElement}
   */
  renderExternal (children) {
    const { external, styles } = this.props;
    if (external) return <ExternalMount className={styles["loading-container"]}>{children}</ExternalMount>;
    else return children;
  }

  /**
   * 渲染函数
   * @returns {null|ReactElement}
   */
  render () {
    const { isShow, type, showMask, size, horizontal, showText, local: propsLocal, className, styles } = this.props;
    const classes = cx(styles.loading, className, {
      [styles["loading-" + type]]: !!type,
      [styles["loading-" + size]]: !!size,
      [styles["loading-horizontal"]]: !!horizontal
    });
    if (!isShow) return null;
    const local = Object.assign({}, getDefaultLocal(), propsLocal);
    return this.renderExternal(
      <Fragment>
        {showMask && <div className={styles["loading-mask"]}/>}
        <div className={classes}>
          <img className={styles["loading-img"]} src={local.img} alt="loading"/>
          {showText && <div className={styles["loading-text"]}>{local.text}</div>}
        </div>
      </Fragment>
    );
  }
}

// 参数变量类型检测
LoadingComponent.propTypes = propTypes;
LoadingComponent.defaultProps = defaultProps;
export default LoadingComponent;
