/*
 * Author: sh-home
 * Create Time: 2020/4/15 9:33
 */
import { login as loginApi, logout as logoutApi } from "../../interface/tleave/mobile";
import { getUserInfo } from "../../interface/tleave/user";
import * as Action from "./action";

/**
 * @typedef {object} action
 * @property {string} type
 */

/**
 * @description 登录
 * @param {loginInfoDTO} params 登录数据
 * @returns {function(function(action)):Promise<UserInfo>}
 */
export const login = (params) => (dispatch) => {
  dispatch(Action.loginStart());
  return loginApi(params).then(({ __response__: { status }, userId }) => {
    if (status === 201) {
      return Promise.reject({ type: "首次登录", userId });
    } else {
      dispatch(Action.loginSuccess());
      return getUserInfo();
    }
  }).then((user) => {
    dispatch(Action.update(user));
    return user;
  }).catch((error) => {
    dispatch(Action.loginFail());
    throw error;
  });
};

/**
 * @description 同步更新本地用户数据
 * @returns {function(function(action)):Promise<UserInfo>}
 */
export const asyncUserInfo = () => dispatch => {
  getUserInfo().then((user) => {
    dispatch(Action.update(user));
    return user;
  });
};

/**
 * @description 自动识别登录状态
 * @returns {function(function):Promise}
 */
export const autoLogin = () => dispatch => {
  return getUserInfo().then((user) => {
    dispatch(Action.loginSuccess());
    dispatch(Action.update(user));
  }).catch(() => {
    dispatch(Action.clear());
  });
};

/**
 * @description 退出登录
 * @returns {function(function):Promise}
 */
export const logout = () => dispatch => {
  return logoutApi().catch(() => 0).then(() => {
    dispatch(Action.clear());
  });
};
