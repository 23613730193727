/*
 * Author: sh-home
 * Create Time: 2020/4/17 11:21
 */
import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import loadable from "../business/loadable";
import { withRouterPropsTypes } from "../helpers/commPropTypes";
import Healthy from "../scene/Healthy";
import BaseQuestion from "../scene/Healthy/BaseQuestion";
import QuestionWrap from "../scene/Healthy/question/wrap";

const Report = loadable(() => import(/* webpackChunkName: "Healthy/Report" */"src/scene/Healthy/Report"));
const Question = loadable(() => import(/* webpackChunkName: "Healthy/Question" */"src/scene/Healthy/question"));

/**
 * 信息采集
 * @param {{path:string}}match
 * @returns {ReactElement}
 */
const QuestionRouter = ({ match }) => <BaseQuestion>
  <QuestionWrap>
    <Switch>
      <Route path={`${match.path}/:type`} component={Question}/>
      <Route render={() => <Redirect to={`${match.path}/base-question`}/>}/>
    </Switch>
  </QuestionWrap>
</BaseQuestion>;

/**
 * @description 防疫信息收集根路由
 * @param {{path:string}} match
 * @param {Location} location
 * @param {History} history
 * @returns {ReactElement}
 * @constructor
 */
const HealthyRouter = ({ match, location, history }) => <Healthy location={location} history={history}>
  <Switch>
    <Route path={`${match.path}/report`} component={Report}/>
    <Route path={`${match.path}/question`} component={QuestionRouter}/>
    {/* <Route render={() => <Redirect to={`${match.path}/question/base-question`}/>}/> */}
    <Route render={() => <Redirect to={`${match.path}/report`}/>}/>
  </Switch>
</Healthy>;

HealthyRouter.propTypes = withRouterPropsTypes;
QuestionRouter.propTypes = withRouterPropsTypes;

export default HealthyRouter;
