/*
 * Author: bjiang
 * Create Time: 2019/11/2 9:32
 */
import QueryString from "querystring";

/**
 * 清理空对象
 * @param {*}obj
 * @returns {*}
 */
const clear = (obj) => Object.entries(obj)
  .filter(([, value]) => value !== null && value !== undefined)
  .reduce((p, [key, value]) => Object.assign(p, { [key]: value }), {});

/**
 * 序列化参数
 * @param {*}params
 * @returns {string}
 */
export const stringify = (params) => QueryString.stringify(clear(params));

/**
 * 合并头信息
 * @param {*}target
 * @param {*}source
 * @returns {*}
 */
export const mergeHeaders = (target, ...source) => clear(Object.assign(target, ...source));
