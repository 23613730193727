/*
 * Author: bjiang
 * Create Time: 2019/10/22 08:56
 */

import PropTypes from "prop-types";
import { connect } from "react-redux";

import { pop, push } from "./action";
import { NAME_SPACE } from "./constant";

/**
 * 浏览器控制模块数据
 * @param {string}titles
 * @returns {{}}
 */
const mapStateToProps = ({ [NAME_SPACE]: { titles } }) => {
  document.title = titles.join(" - ");
  return {};
};

/**
 * 浏览器控制模块方法集
 * @param {function}dispatch
 * @returns {{title: {pop: (function(): *), push: (function(*=): *)}}}
 */
const mapDispatchToProps = (dispatch) => ({
  title: {
    push: (title) => dispatch(push(title)),
    pop: () => dispatch(pop())
  }
});

/**
 * @typedef {object} Browser
 * @property {object} title
 * @property {function} title.push
 * @property {function} title.pop
 */

export const propTypes = {
  title: PropTypes.shape({
    push: PropTypes.func,
    pop: PropTypes.func
  })
};
export default connect(mapStateToProps, mapDispatchToProps);
