/*
 * Author: sh-home
 * Create Time: 2020/3/16 10:06
 */

/**
 * 清除空属性
 * @param {*}obj
 * @returns {*}
 */
const clear = (obj) => Object.entries(obj).reduce((o, [key, value]) => {
  if (value !== "" && value !== null && value !== undefined) return Object.assign(o, { [key]: value.toString() });
  return o;
}, {});

export default (...args) => {
  const objects = args.map((obj) => clear(obj));
  return Object.assign(...objects);
};
