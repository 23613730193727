import moment from "moment";
import { array, date, object, string } from "serialyup";
import { getConfigByLocationParam } from "../config";
import baseConfig from "../config/baseQuestion";

const hasOwnProperty = Object.call.bind(Object.prototype.hasOwnProperty);
const isEmptyCheck = string().ensure().trim().required("此项不得为空");
const checkboxCheck = array().required("此项不得为空");
const boolCheck = string().oneOf(["0", "1"], "请选择是或否").required("此项不得为空");
const timeCheck = date().format("YYYY-MM-DD HH:mm:ss").required("此项不得为空");
const selectCheck = string().ensure().trim().required("请选择下拉项").matches(/^([0-9]{1,2})$|[\u4e00-\u9fa5]$/, {
  message: "请选择正确的下拉项",
  excludeEmptyString: true
});

const validateRulesMap = {
  // 多姓名
  names: string().ensure().trim().required("此项不得为空").matches(/^([\u4e00-\u9fa5\\(\\)（）]{2,}(\/|))+$/, "多个人员斜杠分隔，例：'张三/李四'"),
  // 不得为空
  required: string().ensure().trim().required("此项不得为空"),
  place: string().ensure().trim().required("此项不得为空").matches(/^([\u4e00-\u9fa5]+-){2}.+$/, "请完善地址信息")
};

const validationSchema = object().shape({
  illnessInfoIllnessNames: validateRulesMap.names,
  illnessInfoIfIllnessNewOrOld: validateRulesMap.required,
  // 类别情况
  specialFocusType: validateRulesMap.required,
  // 具体说明
  specialFocusRemark: validateRulesMap.required,
  // 重点关注开始时间
  specialFocusStartDate: date({ typeMismatch: "此项不得为空" }).format("YYYY-MM-DD").required("此项不得为空").max(moment().format("YYYY-MM-DD"), "不得晚于今日"),
  // 往来湖北人员姓名(关系)
  attachHubeiPeopleNames: validateRulesMap.names,
  // 往来湖北人员是否认定疑似或确认感染新冠病毒
  attachHubeiPeopleCOVID19: validateRulesMap.required,
  // 返沪原因
  backToShanghaiScheduleReason: validateRulesMap.required,
  // 返沪交通方式
  backToShanghaiScheduleCommuteType: validateRulesMap.required,
  // 返沪车牌号、车次、航班号
  backToShanghaiScheduleCommuteIdentifyNumber: validateRulesMap.required,
  // 离沪原因
  leaveShanghaiScheduleReason: validateRulesMap.required,
  // 离沪交通方式
  leaveShanghaiScheduleCommuteType: validateRulesMap.required,
  // 离沪车牌号、车次、航班号
  leaveShanghaiScheduleCommuteIdentifyNumber: validateRulesMap.required,
  // 返沪后实际居住地址
  backToShanghaiScheduleLivePlace: validateRulesMap.place,
  // 返沪出发城市
  // backToShanghaiScheduleFromCity: validateRulesMap.place,
  // 离沪后实际居住地址
  leaveShanghaiScheduleLivePlace: validateRulesMap.place
});

/**
 * @param {*} values
 * @param {Array} keys
 * @returns {Promise}
 */
const validateByKeys = (values, keys) => {
  return new Promise((resolve, reject) => {
    validationSchema.validate(values)
      .then(() => resolve({}))
      .catch(err => {
        const errors = keys.reduce((map, key) => {
          if (hasOwnProperty(err, key) && err[key]) {
            map[key] = err[key];
          }
          return map;
        }, {});
        return Object.keys(errors).length > 0 ? reject(errors) : resolve({});
      });
  });
};

// 收集base-question中有需要补充的项  ifFeverOrCough-1 => history.push("./illnessInfo")
const linkOptionMap = baseConfig.reduce((map, elm) => {
  if (hasOwnProperty(elm, "options")) {
    elm.options.forEach(item => hasOwnProperty(item, "linkTo") && map.set(`${elm.name}-${item.value}`, item.linkTo));
  }
  return map;
}, new Map());

/**
 * 提交校验
 * @param {*} values
 * @returns {Promise}
 */
const checkSubmit = values => {
  const task = [];
  Object.entries(values).forEach(([key, value]) => {
    const linkTo = `${key}-${value}`;
    // 是否有补充项
    if (linkOptionMap.has(linkTo)) {
      // 补充项类别如illnessInfo
      const type = linkOptionMap.get(linkTo);
      task.push(new Promise((resolve, reject) => {
        // 补充项目
        const keys = getConfigByLocationParam(type).reduce((list, { name }) => list.concat(name), []);
        // 校验补充项目
        validateByKeys(values, keys)
          .then(() => resolve())
          .catch(() => reject(new Error(type)));
      }));
    }
  });
  if (task.length === 0) return Promise.resolve();
  return Promise.all(task);
};

export {
  isEmptyCheck,
  checkboxCheck,
  boolCheck,
  selectCheck,
  timeCheck,
  validationSchema,
  validateByKeys,
  checkSubmit
};
