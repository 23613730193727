/*
 * Author: sh-home
 * Create Time: 2020/4/21 9:01
 */

import { useEffect } from "react";
import { withRouter } from "react-router-dom";
import connectUser, { propTypes as UserProps } from "src/services/profile/User";
import { withRouterPropsTypes } from "../helpers/commPropTypes";
import connectAuthority, { propTypes as AuthorityProps } from "../services/profile/Authority";

/**
 * @typedef {object} AutoLoginProps
 * @property {Node} children
 * @property {function():Promise} autoLogin
 * @property {function():boolean} isLogin
 * @property {function():Promise} logout
 * @property {function(string):boolean} isAllow
 * @property {location} location
 * @property {history} history
 */
const propTypes = {
  ...UserProps,
  ...AuthorityProps,
  ...withRouterPropsTypes
};

/**
 * @description 自动登录模块
 * @param {AutoLoginProps}props
 * @returns {*}
 * @constructor
 */
function AutoLogin (props) {
  const { children = null, autoLogin, isLogin, logout, isAllow, location: { pathname }, history } = props;
  // 自动登录
  useEffect(() => {
    autoLogin().catch(() => isLogin && logout()).catch(() => 0);
  }, []);
  // 权限拦截
  useEffect(() => {
    if (!isAllow(pathname)) history.replace("/");
  }, [pathname]);
  return children;
}

AutoLogin.propTypes = propTypes;
export default withRouter(connectUser(connectAuthority(AutoLogin)));
