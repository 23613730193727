/*
 * Author: bjiang
 * Create Time: 2020/5/27 9:12
 */
import { arrayOf, func, shape, string } from "prop-types";
import { connect } from "react-redux";
import { NAME_SPACE } from "./constant";
import { clear, getMenusForType, groupBy, isAllow, update } from "./helper";

/**
 * @typedef {object} PermitNode
 * @property {string} label
 * @property {string} href
 * @property {string} icon
 * @property {"个人办公"|"办公管理"} type
 */

/**
 * @typedef {object} AuthorityState
 * @property {Array<PermitNode>} permit
 */

/**
 * @param {AuthorityState} state
 * @returns {AuthorityState}
 */
const mapStateToProps = ({ [NAME_SPACE]: state }) => state;

const mapDispatchToProps = { update, groupBy, getMenusForType, isAllow, clear };

export default connect(mapStateToProps, mapDispatchToProps);

/**
 * @typedef {object} AuthorityTypes
 * @property {Array<PermitNode>} permit
 * @property {Array<MenuItemDTO>} dynamics
 * @property {function} updatePermit
 * @property {function} groupBy
 * @property {function} getMenusForType
 * @property {function} isAllow
 * @property {function} clear
 */

export const propTypes = {
  permit: arrayOf(shape({ label: string, href: string, icon: string, type: string })),
  dynamics: arrayOf(shape({ label: string, processDefineKey: string, scope: string, icon: string, url: string })),
  update: func,
  groupBy: func,
  getMenusForType: func,
  isAllow: func,
  clear: func
};
