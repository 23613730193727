import "normalize.css";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import "src/assets/public.less";
import "./plugIn";
import Routers from "./Router";
import { store } from "./store";

if (process.env.isMiniProgram) {
  require("src/assets/MiniProgramsHocks.less");
}

/**
 * @description 创建入口
 */
export default function createApp () {
  const container = document.createElement("div");
  container.id = "app";
  document.body.appendChild(container);
  ReactDOM.render(
    <Provider store={store}>
      <Routers/>
    </Provider>, container);
}

if (!(typeof wx !== "undefined" && wx.getSystemInfo)) createApp();
