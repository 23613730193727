/*
 * Author: bjiang
 * Create Time: 2020/5/7 15:20
 */

import cx from "classnames";
import PropTypes from "prop-types";
import React, { Fragment, useEffect, useImperativeHandle, useState } from "react";
import { noop } from "src/ui/util";
import STYLES from "./styles.module.less";

/**
 * @typedef {ToastProps} TipProps
 * @property {string} className;
 */

const propTypes = {
  className: PropTypes.string,
  styles: PropTypes.object,
  message: PropTypes.node,
  duration: PropTypes.number,
  checkOver: PropTypes.bool,
  mask: PropTypes.bool,
  onHide: PropTypes.func
};

const defaultProps = {
  duration: 2000,
  checkOver: false,
  mask: false,
  onHide: noop,
  styles: STYLES
};
/**
 * @description 添加全局部触达事件
 * @param {function}fn
 */
const addOverEvent = (fn) => {
  document.body.addEventListener("click", fn);
  document.body.addEventListener("touchend", fn);
};
/**
 * @description 删除全局部触达事件
 * @param {function}fn
 */
const removeOverEvent = (fn) => {
  document.body.removeEventListener("click", fn);
  document.body.removeEventListener("touchend", fn);
};

const Tip = React.forwardRef(
  /**
   * @description 提示组件
   * @param {TipProps}props
   * @param {Ref}ref
   * @returns {*}
   */
  (props, ref) => {
    const { className, styles, message, mask, duration, onHide, checkOver } = props;
    const [exit, setExit] = useState(false);
    // 挂在hide方法
    useImperativeHandle(ref, () => ({ hide: onHide }));
    // 启动关闭定时器
    useEffect(() => {
      duration > 0 && setTimeout(() => setExit(true), duration);
    }, []);
    // 监听外部点击事件
    useEffect(() => {
      checkOver ? addOverEvent(onHide) : removeOverEvent(onHide);
      return () => checkOver && removeOverEvent(onHide);
    }, [checkOver]);
    const handleTransitionEnd = () => exit && onHide();
    return (
      <Fragment>
        {mask && <div className={styles.mask}/>}
        <div className={cx(className, styles.tip, { [styles.exit]: exit })}
          ref={ref} onTransitionEnd={handleTransitionEnd}>
          {message}
        </div>
      </Fragment>
    );
  }
);

Tip.displayName = "Tip";
Tip.propTypes = propTypes;
Tip.defaultProps = defaultProps;

export default Tip;
