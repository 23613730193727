/*
 * Author: bjiang
 * Create Time: 2020/5/27 9:11
 */

import { CLEAR, UPDATE } from "./constant";

/**
 * @description 更新本地权限列表
 * @param {Array<PermitNode>}permit
 * @param {Array<MenuItemDTO>} dynamics
 * @returns {{permit: Array<PermitNode>, type: string}}
 */
export const update = (permit, dynamics) => ({ type: UPDATE, permit, dynamics });

/**
 * @description 清空当前用户权限组
 * @returns {{type: string}}
 */
export const clear = () => ({ type: CLEAR });
