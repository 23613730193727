/*
 * Author: bjiang
 * Create Time: 2019/11/8 17:22
 */

import { setDefaultLocal as setLoadingLocal } from "src/ui/loading";
import { setDefaultLocal as setModalLocal, setDefaultStyles as setModalStyles } from "src/ui/Modal";

import modalStyles from "../assets/ui/modal.module.less";

setModalStyles(modalStyles);
setModalLocal({
  close: ""
});

setLoadingLocal({
  text: "加载中，请稍后...",
  img: require("./loading.svg")
});
/**
 * @description 设置虚拟vh值
 */
const setVH = () => {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);
};

setVH();

window.addEventListener("resize", setVH);
