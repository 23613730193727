import React from "react";
import { Route, Switch } from "react-router-dom";
import loadable from "../business/loadable";
import { withRouterPropsTypes } from "../helpers/commPropTypes";

const List = loadable(() => import(/* webpackChunkName: "Leave/List" */"src/scene/Leave/List"));
const ManagerList = loadable(() => import(/* webpackChunkName: "Leave/List/Manager" */"src/scene/Leave/List/Manager"));
const Detail = loadable(() => import(/* webpackChunkName: "Leave/Detail" */"src/scene/Leave/Detail"));
const Apply = loadable(() => import(/* webpackChunkName: "Leave/Apply" */"src/scene/Leave/Apply"));
/**
 * @description 请假模块
 * @param {string}path
 * @returns {ReactElement}
 * @constructor
 */
const LeaveRouter = ({ match: { path } }) => (
  <Switch>
    {/* 个人列表 */}
    <Route exact path={`${path}`} component={List}/>
    {/* 申请 */}
    <Route exact path={`${path}/apply`} component={Apply}/>
    {/* 管理列表 */}
    <Route exact path={`${path}/manager`} component={ManagerList}/>
    {/* 管理详情 */}
    <Route path={`${path}/manager/:id`} component={Detail}/>
    {/* 详情 */}
    <Route path={`${path}/:id`} component={Detail}/>
  </Switch>
);

LeaveRouter.propTypes = withRouterPropsTypes;

export default LeaveRouter;
