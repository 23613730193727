/**
 * Create Time 2021/2/7 11:6
 */

import http from "../httpApi";

/**
 * @description 查看用户信息接口
 * @description 查看用户信息接口
 * @param {HttpOptions}[httpOptions] - 额外的查询配置
 * @returns {Promise<userInfoDTO>} - 200 成功运营 successful operation
 */
export const getUserInfo = (httpOptions) => {
  return http.get("/tleave/mobile/user/user-info",
    {}, httpOptions);
};

/**
 * @description 修改用户信息接口
 * @description 修改用户信息接口
 * @param {userInfoDTO} body - 修改用户信息
 * @param {HttpOptions}[httpOptions] - 额外的查询配置
 * @returns {Promise<void>} - 200 成功运营 successful operation
 */
export const updateUserInfo = (body, httpOptions) => {
  return http.put("/tleave/mobile/user/user-info",
    body, httpOptions);
};

/**
 * @description 修改密码接口
 * @description 修改密码接口
 * @param {modifyPWDRQ} body - 修改密码
 * @param {HttpOptions}[httpOptions] - 额外的查询配置
 * @returns {Promise<void>} - 200 成功运营 successful operation
 */
export const modifyPwd = (body, httpOptions) => {
  return http.put("/tleave/mobile/user/modifyPwd",
    body, httpOptions);
};

/**
 * @description 忘记密码接口
 * @description 忘记密码接口
 * @param {forgetPwdDTO} body - 忘记密码接口
 * @param {HttpOptions}[httpOptions] - 额外的查询配置
 * @returns {Promise<void>} - 200 成功运营 successful operation
 */
export const forgetPwd = (body, httpOptions) => {
  return http.post("/tleave/mobile/user/forgetPwd",
    body, httpOptions);
};
