/*
  name: 对应form表单中values的对应字段
  type: 对应该问题需要引用的组件
  label: 为问题描述
  optional: 部分问题为下拉框，此为下拉选项
 */
export default [
  {
    label: "具体说明",
    name: "attachCOVID19Remark",
    type: "input",
    options: {
      placeholder: "请备注该人员信息(姓名、关系、目前所在地、经过等）"
    }
  }
];
