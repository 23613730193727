/*
 * Author: bjiang
 * Create Time: 2020/5/20 9:31
 */
import loadable from "@loadable/component";
import React from "react";
import Loading from "../ui/loading";

/**
 * @description 加载页面
 * @param {function():Promise} module
 * @returns {Component}
 */
export default module => loadable(module, { fallback: <Loading/> });
