/*
 * Author: bjiang
 * Create Time: 2019/11/8 14:02
 */

import cx from "classnames";
import PropTypes from "prop-types";
import React, { Fragment } from "react";
import Button from "../Button";
import Icon from "../Icon";
import STYLES from "./style.module.less";

/**
 * @typedef {object} ModalPropsOpt
 * @property {object} [styles] 全局样式替换
 * @property {string} [className] classes
 * @property {"xs"|"sm"|"md"|"lg"|"full"} [size] Modal大小
 * @property {string|ReactElement} [icon] 图标
 * @property {"primary"|"warning"|"secondary"|"danger"|"success"|"info"|"light"|"dark"} [theme] 图标颜色
 * @property {boolean} [centered] 是否垂直居中展示
 * @property {boolean} [showMask] 是否显示遮罩层
 * @property {boolean} [maskOnClose] 点击遮罩层是否关闭
 * @property {boolean} [hasClose] 是否显示关闭按钮
 * @property {string} [title] Title
 * @property {string|ReactElement} content 主体内容
 * @property {ReactElement} [footer] 自定义底部内容
 * @property {string} [footerClass] 底部的附加样式
 * @property {{close:string}} [local] 底部的附加样式
 * @property {function} [onCancel] 关闭按钮事件
 */

/**
 * @typedef {ModalPropsOpt} ModalProps
 * @property {"alert"|"confirm"|"popup"} type Modal的类型
 * @property {function} onClose 关闭Modal
 */

// 指定参数类型
const propTypes = {
  /** 样式组 **/
  // Modal的类型
  type: PropTypes.oneOf(["alert", "confirm", "popup"]),
  // 全局样式替换
  styles: PropTypes.object,
  // Additional classes.
  className: PropTypes.string,
  // Modal大小
  size: PropTypes.oneOf(["xs", "sm", "md", "lg", "full"]),
  // 图标
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  // 图标颜色
  theme: PropTypes.oneOf(["primary", "warning", "secondary", "danger", "success", "info", "light", "dark"]),
  // 是否垂直居中展示
  centered: PropTypes.bool,
  // 是否显示遮罩层
  showMask: PropTypes.bool,
  /** 功能组 **/
  // 点击遮罩层是否关闭
  maskOnClose: PropTypes.bool,
  // 是否显示关闭按钮
  hasClose: PropTypes.bool,
  /** 内容组 **/
  // Title
  title: PropTypes.string,
  // 主体内容
  content: PropTypes.node.isRequired,
  // 自定义底部内容
  footer: PropTypes.node,
  // 底部的附加样式
  footerClass: PropTypes.string,
  local: PropTypes.shape({
    close: PropTypes.string
  }),
  /** 事件组 **/
  // 关闭按钮事件
  onCancel: PropTypes.func,
  // 关闭Modal
  onClose: PropTypes.func.isRequired
};

// 参数添加默认值
const defaultProps = {
  type: "alert",
  styles: STYLES,
  showMask: true,
  centered: false,
  maskOnClose: true,
  hasClose: true,
  theme: "primary",
  footer: null,
  onCancel: () => null
};

/**
 * 弹窗组件
 */
class Modal extends React.PureComponent {
  /**
   * 构造函数
   */
  constructor () {
    super();
    this.state = {};
    this.ref = React.createRef();
    this.handleClickOut = this.handleClickOut.bind(this);
    this.handleModalCancel = this.handleModalCancel.bind(this);
  }

  /**
   * 关闭弹窗
   */
  close () {
    this.props.onCancel();
    this.props.onClose();
  }

  /**
   * 处理外部点击事件
   * @param {Event} event
   */
  handleClickOut (event) {
    // 如果点击的是内部元素，则不做任何处理
    if (this.ref.current.contains(event.target)) return;
    // 如果maskOnClose为true，则执行关闭动作
    this.props.maskOnClose && this.handleModalCancel(event);
  }

  /**
   * 处理取消事件
   * @param {Event} event
   */
  handleModalCancel (event) {
    this.props.onCancel(event);
    this.props.onClose(event);
  }

  /**
   * 生命周期函数，组件加载完成时触发
   */
  componentDidMount () {
    document.body.addEventListener("click", this.handleClickOut);
    document.body.addEventListener("touchend", this.handleClickOut);
  }

  /**
   * 生命周期函数，组件卸载时触发
   */
  componentWillUnmount () {
    document.body.removeEventListener("click", this.handleClickOut);
    document.body.removeEventListener("touchend", this.handleClickOut);
  }

  /**
   * 渲染函数
   * 渲染页脚
   * @returns {Component}
   */
  renderFooter () {
    const { footer: _footer, footerClass, onClose, styles } = this.props;
    const footer = _footer && _footer.type === Fragment ? _footer.props.children : _footer;
    if (!footer) return null;
    return <div className={cx(styles.footer, footerClass)}>{
      React.Children.toArray(footer).map(btn => React.cloneElement(btn, {
        onClick: (event) => {
          btn.props.onClick && btn.props.onClick(event);
          onClose(event);
        }
      }))
    }</div>;
  }

  /**
   * 渲染函数
   * @returns {Component}
   */
  render () {
    const {
      type, icon, theme, size, className, styles,
      centered, hasClose, showMask, title, local,
      content: propContent
    } = this.props;
    const classesWarp = cx(styles.warp, className, {
      [styles.centered]: centered,
      [styles[size]]: !!size,
      [styles[type]]: !!type,
      [styles[theme]]: !!theme
    });
    const content = React.isValidElement(propContent)
      ? React.cloneElement(propContent, { close: this.close.bind(this) })
      : propContent;
    return (
      <Fragment>
        {showMask && <div className={styles.mask}/>}
        <div className={classesWarp} ref={this.ref}>
          <div className={styles.modal}>
            {/* hasClose */}
            {hasClose && (<Button className={styles.close} normal onClick={this.handleModalCancel}>
              <Icon name="close" size={2}/>{local.close}
            </Button>)}
            {/* icon */}
            {typeof icon === "string" && <div className={styles.icon}>
              <Icon name={icon} size={6}/>
            </div>}
            {React.isValidElement(icon) && <div className={styles.icon}>{icon}</div>}
            {/* title */}
            {title && (<h4 className={styles.header}>{title}</h4>)}
            {/* content */}
            <div className={styles.content}>{content}</div>
            {this.renderFooter()}
          </div>
        </div>
      </Fragment>
    );
  }
}

Modal.propTypes = propTypes;
Modal.defaultProps = defaultProps;

export default Modal;
