import cx from "classnames";
import PropTypes from "prop-types";
import React from "react";
import style from "./style.module.less";

// 指定参数类型
const propTypes = {
  type: PropTypes.string,
  /** Additional classes. */
  className: PropTypes.string,
  /** A button can click */
  disabled: PropTypes.bool,
  // 按钮的样式
  btnType: PropTypes.oneOf(["primary", "secondary", "warning", "danger", "success", "info", "light", "dark"]),
  // 大小，small,large
  size: PropTypes.oneOf(["small", "large"]),
  // 宽度100%
  block: PropTypes.bool,
  // 带边框的button
  outline: PropTypes.bool,
  // 左上角圆角
  cornerLT: PropTypes.bool,
  // 右上角圆角
  cornerRT: PropTypes.bool,
  // 左下角圆角
  cornerLB: PropTypes.bool,
  // 右下角圆角
  cornerRB: PropTypes.bool,
  // 圆角
  corner: PropTypes.bool,
  // 是否是链接样式
  isLink: PropTypes.bool,
  // 是否是默认按钮样式
  normal: PropTypes.bool,
  children: PropTypes.any
};
/**
 * @description 空函数
 * @returns {undefined}
 */
const noop = () => undefined;
// 参数添加默认值
const defaultProps = {
  type: "button",
  isLink: false,
  normal: false,
  onClick: noop
};

const Button = React.forwardRef((props, ref) => {
  const {
    btnType, // 按钮的样式，primary, secondary, warning, danger, success, info, light, dark
    size, // 大小，small,large
    block, // 宽度100%
    disabled, // disabled
    outline, // 带边框的button
    cornerLT, // 左上角圆角
    cornerRT, // 右上角圆角
    cornerLB, // 左下角圆角
    cornerRB, // 右下角圆角
    corner, // 圆角
    className,
    type,
    isLink,
    normal,
    children,
    ...restProps
  } = props;
  const classes = cx(className, style.button, {
    [style["btn-" + btnType]]: !outline,
    [style["btn-outline-" + btnType]]: !!outline,
    [style["btn-" + size]]: !!size,
    [style["btn-corner-lt"]]: cornerLT,
    [style["btn-corner-rt"]]: cornerRT,
    [style["btn-corner-lb"]]: cornerLB,
    [style["btn-corner-rb"]]: cornerRB,
    [style["btn-corner"]]: corner,
    [style["btn-link"]]: isLink,
    [style["btn-auto"]]: normal,
    [style["btn-block"]]: block,
    [style["btn-disabled"]]: disabled
  });
  delete restProps.forwardedRef;
  return (
    <button {...restProps} className={classes} type={type} disabled={disabled} ref={ref}>
      {children}
    </button>
  );
});

Button.displayName = "Button";
// 参数变量类型检测
Button.propTypes = propTypes;
Button.defaultProps = defaultProps;

export default Button;
