/*
 * Author: sh-home
 * Create Time: 2020/4/17 11:19
 */
import React from "react";
import { Link } from "react-router-dom";
import { withRouterPropsTypes } from "../../helpers/commPropTypes";

/**
 * @description 404页面
 * @param {Location}location
 * @returns {ReactElement}
 */
const Exp404 = ({ location }) => <div style={{ fontSize: "0.5rem" }}>
  <div>404 for [{location.pathname}]</div>
  <div><Link to={"/"}>回首页</Link></div>
</div>;

Exp404.propTypes = withRouterPropsTypes;

export default Exp404;
