/*
 * Author: bjiang
 * Create Time: 2019/9/24 14:23
 */

/**
 * @typedef {object} ApiError
 * @property {string} devMessage - 详细描述错误的消息，以帮助开发人员了解有关此问题的更多信息 A message describing the error in more detail to help the developer learn more about the issue
 * @property {string} errorCode - 描述错误类型的弹簧验证码/Openjaw错误码/供应商错误 A Spring validation code/Openjaw error code/Supplier Error describing the type of error
 * @property {string} [field] - 出错的字段 The field which was at fault for the error
 * @property {string} userMessage - 用简单语言描述错误的消息。在某些情况下可能与devMessage相同 A message describing the error in simple language. May be the same as devMessage in some cases
 */

/**
 * @description API异常统一构造器
 * @extends Error
 */
class ResponseError extends Error {
  /**
   * @constructor ResponseError
   * @param {string}message 错误消息
   * @param {number|string}status 错误类型
   * @param {Array<ApiError>}[apiErrors] 详细错误
   */
  constructor (message, status, apiErrors = []) {
    super(message);
    this.status = status;
    this.apiErrors = apiErrors;
  }
}

export default ResponseError;
