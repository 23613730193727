/*
 * Author: bjiang
 * Create Time: 2020/5/27 10:19
 * description 系统访问权限配置中心
 */

/*
 配置说明
 通过字符串前缀来说明配置，
 长路径覆盖短路基规则
 前缀说明：
 + 默认准许
 _ 需要获得准许,默认拒绝访问
 */

export default [
  ["+", "/"],
  ["-", "/hospitality/manager"],
  ["-", "/hospitality"],
  ["-", "/badge"],
  ["-", "/badge/manager"],
  ["-", "/overtime"],
  ["-", "/overtime/manager"],
  ["-", "/businesstrip"],
  ["-", "/businesstrip/manager"],
  ["-", "/leave"],
  ["-", "/leave/manager"],
  ["-", "/employ/manager"]
];
