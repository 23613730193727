/*
 * Author: sh-home
 * Create Time: 2020/3/27 14:33
 */

import PropTypes from "prop-types";

const { bool, any, func, number } = PropTypes;

/**
 * @typedef {object} FormikSharedConfig
 * @property {boolean} validateOnChange
 * @property {boolean} validateOnBlur
 * @property {boolean|function} isInitialValid
 * @property {boolean} enableReinitialize
 */
const FormikSharedConfig = {
  validateOnChange: bool,
  validateOnBlur: bool,
  isInitialValid: PropTypes.oneOfType([bool, func]),
  enableReinitialize: bool
};

/**
 * @typedef {object} FormikState
 * @property {any} values
 * @property {any} error
 * @property {any} errors
 * @property {any} touched
 * @property {boolean} isValidating
 * @property {boolean} isSubmitting
 * @property {any} status
 * @property {number} submitCount
 */

const FormikState = {
  values: any,
  error: any,
  errors: any,
  touched: any,
  isValidating: bool,
  isSubmitting: bool,
  status: any,
  submitCount: number
};

/**
 * @typedef {object} FormikActions
 * @property {function} setStatus
 * @property {function} setError
 * @property {function} setErrors
 * @property {function} setSubmitting
 * @property {function} setTouched
 * @property {function} setValues
 * @property {function} setFieldValue
 * @property {function} setFieldError
 * @property {function} setFieldTouched
 * @property {function} validateForm
 * @property {function} validateField
 * @property {function} resetForm
 * @property {function} submitForm
 * @property {function} setFormikState
 */
const FormikActions = {
  setStatus: func,
  setError: func,
  setErrors: func,
  setSubmitting: func,
  setTouched: func,
  setValues: func,
  setFieldValue: func,
  setFieldError: func,
  setFieldTouched: func,
  validateForm: func,
  validateField: func,
  resetForm: func,
  submitForm: func,
  setFormikState: func
};

/**
 * @typedef {object} FormikHandlers
 * @property {function} handleSubmit
 * @property {function} handleReset
 * @property {function} handleBlur
 * @property {function} handleChange
 */
const FormikHandlers = {
  handleSubmit: func,
  handleReset: func,
  handleBlur: func,
  handleChange: func
};

/**
 * @typedef {object} FormikHandlers
 * @property {boolean} dirty
 * @property {boolean} isValid
 * @property {any} initialValues
 */
const FormikComputedProps = {
  dirty: bool,
  isValid: bool,
  initialValues: any
};

/**
 * @typedef {object} FormikRegistration
 * @property {function} registerField
 * @property {function} unregisterField
 */
const FormikRegistration = {
  registerField: func,
  unregisterField: func
};

/**
 * @typedef {FormikSharedConfig & FormikState & FormikActions & FormikHandlers & FormikComputedProps & FormikRegistration} FormikProps
 */
export const FormikProps = {
  ...FormikSharedConfig,
  ...FormikState,
  ...FormikActions,
  ...FormikHandlers,
  ...FormikComputedProps,
  ...FormikRegistration
};
