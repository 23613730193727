/**
 * Created with WebStorm.
 * User: zyliao
 * Date: 2020/10/29
 * Time: 20:34
 * To change this template use File | Settings | File Templates.
 * Describe:授权输入校验
 */
import * as Yup from "serialyup";
import { string } from "serialyup";

const VALIDATION_PARAM = Yup.object({
  //  employeeCurrentPosCity (string): 地址填写
  employeeCurrentPosCity: string().ensure().trim().matches(/^\S+-\S+-.+$/, "地址填写不可为空！"),
  // ifAroundOrSelfFeverOrCough (string): 本人或周边人员目前是否有发热、咳嗽、乏力、呕吐等症状？
  ifAroundOrSelfFeverOrCough: string(),
  // illnessInfoIllnessNames(string): 生病人员姓名（关系）
  illnessInfoIllnessNames: string(),
  // illnessInfoIfIllnessNewOrOld(string): 生病情况
  illnessInfoIfIllnessNewOrOld: string(),
  // attachHubeiRelPeopleSituation: string()
  attachHubeiRelPeopleSituation: string(),
  // ifAttachCOVID19: string():是否直接或间接接触过疑似或确诊人员？
  ifAttachCOVID19: string(),
  // attachCOVID19Remark: string()
  attachCOVID19Remark: string(),
  // ifCOVID19: string() 是否认定疑似或确诊感染新冠病毒？
  ifCOVID19: string(),
  // covid19Remark: string() 是否认定疑似或确诊感染新冠病毒具体说明
  covid19Remark: string(),
  // ifSelfIsolate: string() 是否被隔离
  ifSelfIsolate: string(),
  // isolateReason: string() 是否被隔离的类别情况
  isolateReason: string(),
  // isolateRemark: string()
  isolateRemark: string(),
  // ifSelfOrAroundIllnessToday: string() 是否被隔离的类别情况
  ifSelfOrAroundIllnessToday: string(),
  ifSpecialFocus: string(),
  crtDate: string(),
  employeeName: string(),
  ifRecord: string(),
  leftSubmitAvailableMillisecond: string(),
  parseDate: string(),
  ifFeverOrCough: string(),
  ifGoToDoctor: string(),
  ifRecentBackToShanghaiSchedule: string(),
  ifRecentLeaveShanghaiSchedule: string(),
  specialInstructions: string()

});
export default VALIDATION_PARAM;
