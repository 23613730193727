/*
 * Author: bjiang
 * Create Time: 2020/4/27 16:25
 */
import React from "react";
import { Route, Switch } from "react-router-dom";
import loadable from "../business/loadable";
import { withRouterPropsTypes } from "../helpers/commPropTypes";

const BadgeList = loadable(() => import(/* webpackChunkName: "Badge/List" */"src/scene/Badge/List"));
const BadgeManagerList = loadable(() => import(/* webpackChunkName: "Badge/List/Manager" */"src/scene/Badge/List/Manager"));
const BadgeSelect = loadable(() => import(/* webpackChunkName: "Badge/select" */"src/scene/Badge/Select"));
const BadgeApply = loadable(() => import(/* webpackChunkName: "Badge/apply" */"src/scene/Badge/Apply"));
const BadgeDetail = loadable(() => import(/* webpackChunkName: "Badge/detail" */"src/scene/Badge/Detail"));
/**
 * @description 证章模块
 * @param {string}path
 * @returns {ReactElement}
 * @constructor
 */
const BadgeRouter = ({ match: { path } }) => (
  <Switch>
    {/* 个人列表 */}
    <Route exact path={`${path}`} component={BadgeList}/>
    {/* 申请 */}
    <Route exact path={`${path}/apply`} component={BadgeSelect}/>
    <Route exact path={`${path}/apply/:badgeType`} component={BadgeApply}/>
    {/* 管理列表 */}
    <Route exact path={`${path}/manager`} component={BadgeManagerList}/>
    {/* 管理详情 */}
    <Route path={`${path}/manager/:id`} component={BadgeDetail}/>
    {/* 详情 */}
    <Route path={`${path}/:id`} component={BadgeDetail}/>
  </Switch>
);

BadgeRouter.propTypes = withRouterPropsTypes;

export default BadgeRouter;
