/*
 * Author: sh-home
 * Create Time: 2020/5/10 18:15
 */
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import Browser from "src/business/Browser";
import { withRouterPropsTypes } from "src/helpers/commPropTypes";
import Icon from "src/ui/Icon";
import bg from "./bg.png";
import styles from "./styles.module.less";

/**
 * @typedef {withRouterProps} HealthyProps
 * @property {ReactElement} children
 */
const propTypes = {
  children: PropTypes.node,
  location: withRouterPropsTypes.location
};

const defaultProps = {};

/**
 * @description 信息采集组件
 */
class Healthy extends Component {
  /**
   * @description 渲染函数
   * @returns {Comment}
   */
  render () {
    const { children, location: { pathname } } = this.props;
    let to = "/entrance";
    if (pathname.indexOf("/healthy/question/base-question") >= 0) {
      to = "../report";
    } else if (pathname.indexOf("/healthy/question") !== -1) {
      to = "./base-question";
    }

    return (
      <Browser name={"员工信息采集"}>
        <div className={styles.header}>
          <div className={styles["nav-bar"]}>
            <div className={styles.left}>
              <Link to={to}>
                <Icon name={"arrow-chevron-left"}/></Link>
            </div>
            <div className={styles.title}>{"员工信息采集"}</div>
          </div>
          <div className={styles.banner}>
            <img src={bg} alt={"bg"}/>
          </div>
        </div>
        <div className={styles.content}>
          {children}
        </div>
      </Browser>
    );
  }
}

Healthy.propTypes = propTypes;
Healthy.defaultProps = defaultProps;

export default Healthy;
