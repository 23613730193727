/*
 * Author: bjiang
 * Create Time: 2019/10/22 08:56
 */

import { NAME_SPACE, TITLE_POP, TITLE_PUSH } from "../constant";

const defaultState = {
  titles: [document.title || "上海航信移动OA"]
};

export default {
  [NAME_SPACE]: (state = defaultState, { type, ...action }) => {
    switch (type) {
      case TITLE_PUSH: {
        return {
          ...state,
          titles: [action.title].concat(state.titles)
        };
      }
      case TITLE_POP: {
        return {
          ...state,
          titles: state.titles.slice(1)
        };
      }
      default:
        return state;
    }
  }
};
