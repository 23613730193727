/*
 * Author: zyliao
 * Create Time: 2021
 */

import React from "react";
import { Route, Switch } from "react-router-dom";
import loadable from "../business/loadable";
import { withRouterPropsTypes } from "../helpers/commPropTypes";

const Epidemic = loadable(() => import(/* webpackChunkName: "HomeRouter/List" */"src/scene/Epidemic"));
const Detail = loadable(() => import(/* webpackChunkName: "HomeRouter/List" */"src/scene/Epidemic/Detail"));
/**
 * @description 疫情防控
 * @param {string}path
 * @returns {ReactElement}
 * @constructor
 */
const EpidemicRouter = ({ match: { path } }) => (
  <Switch>
    {/* 公告贴片 */}
    <Route exact path={`${path}`} component={Epidemic}/>
    <Route exact path={`${path}/:module`} component={Detail}/>
  </Switch>
);

EpidemicRouter.propTypes = withRouterPropsTypes;

export default EpidemicRouter;
