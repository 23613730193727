import baseConfig from "./baseQuestion";
import confirmConfig from "./confirm";
import contactConfig from "./contactPerson";
import illnessConfig from "./illnessInfo";
import quarantinedConfig from "./quarantined";
import instructionsConfig from "./specificInstructions";

const configMap = {
  "base-question": baseConfig,
  "quarantined": quarantinedConfig,
  "illnessInfo": illnessConfig,
  "contact-person": contactConfig,
  "confirm": confirmConfig,
  "specificInstructions": instructionsConfig
};

/**
 * 根据url返回采集项
 * @param {string} type
 * @returns {JSON}
 */
export const getConfigByLocationParam = (type) => {
  return configMap[type];
};
