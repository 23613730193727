/*
 * Author: bjiang
 * Create Time: 2020/6/29 9:59
 */
// import installScript from "../helpers/installScript";

// installScript("https://www.googletagmanager.com/gtag/js?id=G-QHWRFX4GVJ").catch();
window.dataLayer = window.dataLayer || [];
window.GA_MEASUREMENT_ID = "G-QHWRFX4GVJ";

window.ga = function () {
  // eslint-disable-next-line no-undef
  window.dataLayer.push(arguments);
};

window.ga("js", new Date());

window.ga("config", window.GA_MEASUREMENT_ID);
