/**
 * Create Time 2021/2/7 11:6
 */

import http, { stringify } from "../httpApi";

/**
 * @description 附件下载接口
 * @description 附件下载接口
 * @param {string} instanceId - 实例ID
 * @param {string} id - 身份证号码 附件ID
 * @param {HttpOptions}[httpOptions] - 额外的查询配置
 * @returns {Promise<attachmentDownloadRS>} - 200 成功运营 successful operation
 */
export const attachmentDownload = (instanceId, id, httpOptions) => {
  return http.get(`/tleave/mobile/sys/attachment/download/${instanceId}/${id}`,
    {}, httpOptions);
};

/**
 * @description 获取菜单信息接口
 * @description 获取菜单信息接口
 * @param {HttpOptions}[httpOptions] - 额外的查询配置
 * @returns {Promise<menuListRS>} - 200 成功运营 successful operation
 */
export const menuList = (httpOptions) => {
  return http.get("/tleave/mobile/sys/menuList",
    {}, httpOptions);
};

/**
 * @description 获取待办事项接口
 * @description 获取待办事项接口
 * @param {HttpOptions}[httpOptions] - 额外的查询配置
 * @returns {Promise<todoListRS>} - 200 成功运营 successful operation
 */
export const todoList = (httpOptions) => {
  return http.get("/tleave/mobile/sys/todoList",
    {}, httpOptions);
};

/**
 * @typedef {object} DictListQuery - dictList 查询构体
 * @property {string}[key] - 值
 * @property {string}[type] - 字典类型
 */
/**
 * @description 获取字典信息接口
 * @description 获取字典信息接口
 * @param {DictListQuery} __query__ - 查询参数
 * @param {HttpOptions}[httpOptions] - 额外的查询配置
 * @returns {Promise<dictListRS>} - 200 成功运营 successful operation
 */
export const dictList = (__query__, httpOptions) => {
  const { key, type } = __query__ || {};
  return http.get(`/tleave/mobile/sys/dictList?${stringify({ key, type })}`,
    {}, httpOptions);
};

/**
 * @description 上分
 * @description 上分OA系统文件下载接口
 * @param {fileDownloadDTO} body - 文件下载
 * @param {HttpOptions}[httpOptions] - 额外的查询配置
 * @returns {Promise<void>} - 200 成功运营 successful operation
 */
export const fileDownload = (body, httpOptions) => {
  return http.post("/tleave/mobile/sys/fileDownload",
    body, httpOptions);
};

/**
 * @typedef {object} UserInfoListQuery - userInfoList 查询构体
 * @property {string}[office] - 部门
 * @property {string}[name] - 姓名
 * @property {string}[loginName] - 登录号或工号
 * @property {string}[email] - 邮箱
 */
/**
 * @description 查询所有用户信息接口
 * @description 查询所有用户信息接口
 * @param {UserInfoListQuery} __query__ - 查询参数
 * @param {HttpOptions}[httpOptions] - 额外的查询配置
 * @returns {Promise<userInfoListRS>} - 200 成功运营 successful operation
 */
export const userInfoList = (__query__, httpOptions) => {
  const { office, name, loginName, email } = __query__ || {};
  return http.get(`/tleave/mobile/sys/userInfoList?${stringify({ office, name, loginName, email })}`,
    {}, httpOptions);
};

/**
 * @description 上分OA批量审批口
 * @description 上分OA系统批量审批接口
 * @param {batchApprovalRQ} body - undefined
 * @param {HttpOptions}[httpOptions] - 额外的查询配置
 * @returns {Promise<batchApprovalRS>} - 200 成功运营 successful operation
 */
export const batchApproval = (body, httpOptions) => {
  return http.post("/tleave/mobile/sys/batchApproval",
    body, httpOptions);
};
