/*
 * Author: sh-home
 * Create Time: 2020/5/10 18:04
 */
import React from "react";

const Context = React.createContext({});
const { Provider } = Context;

export default Context;
export { Provider };
