/*
 * Author: bjiang
 * Create Time: 2020/5/22 9:25
 */

import { setConfig } from "../helpers/DICT";
import { setOptions } from "../services/interface/httpApi";
import { dictList } from "../services/interface/tleave/sys";
import { logout } from "../services/profile/User/action";
import { store } from "../store";

setOptions({
  clearUser: () => store.dispatch(logout)
});

setConfig({ getDb: dictList });
