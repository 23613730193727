/**
 * Create Time 2020/10/27 10:41
 */

import http from "../../httpApi";

/**
 * @description 含展示首页菜单 和 可以跳转菜单地址
 * @description 含展示首页菜单 和 可以跳转菜单地址
 * @param {HttpOptions}[httpOptions] - 额外的查询配置
 * @returns {Promise<MenuItemDTO[]>} - 200 OK
 * @returns {Promise<Error[]>} - 400 统一业务异常
 * @returns {Promise<Error[]>} - 401 登录信息不对
 * @returns {Promise<Error[]>} - 403 用户没有权限访问指定资源或者操作
 * @returns {Promise<Error>} - 404 未找到资源
 * @returns {Promise<Error>} - 500 服务器内部错误
 */
export const listMenu = (httpOptions) => {
  return http.get("/tleave/arrow/menu",
    {}, httpOptions);
};
