/*
  name: 对应form表单中values的对应字段
  type: 对应该问题需要引用的组件
  label: 为问题描述
  optional: 部分问题为下拉框，此为下拉选项
 */
export default [
  {
    label: "往来湖北人员姓名(关系)",
    name: "attachHubeiPeopleNames",
    type: "input",
    options: {
      placeholder: "多个人员斜杠分隔，例：'张三/李四'"
    }
  },
  {
    label: "往来湖北人员是否认定疑似或确认感染新冠病毒",
    name: "attachHubeiPeopleCOVID19",
    type: "radio",
    options: [
      { value: "0", label: "否" },
      { value: "1", label: "是" }
    ]
  }
];
