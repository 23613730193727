/*
 * Author: bjiang
 * Create Time: 2019/10/13 16:55
 */
import moment from "moment";

import(
  /* webpackChunkName: "local/[request]" */
  /* webpackMode: "eager" */
  /* webpackInclude: /(zh-cn|en).js$/ */
  "moment/locale/zh-cn").then(() => {
  moment.updateLocale(moment.locale(), { week: { dow: 0 } });
});
