/**
 * Created with WebStorm.
 * User: Tlionsir
 * Date: 2020/9/3
 * Time: 17:52
 * To change this template use File | Settings | File Templates.
 * Describe:
 */
import React from "react";
import { Route, Switch } from "react-router-dom";
import loadable from "../business/loadable";
import { withRouterPropsTypes } from "../helpers/commPropTypes";

const Index = loadable(() => import(/* webpackChunkName: "CancellationLeave" */"src/scene/CancellationLeave"));
/**
 * @description 销假模块
 * @param {string}path
 * @returns {ReactElement}
 * @constructor
 */
const CancellationLeave = ({ match: { path } }) => (
  <Switch>
    {/* 销假 */}
    <Route path={`${path}/:id`} component={Index}/>
  </Switch>
);

CancellationLeave.propTypes = withRouterPropsTypes;

export default CancellationLeave;
