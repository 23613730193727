/**
 * Create Time 2021/2/7 11:6
 */

import http, { stringify } from "../httpApi";

/**
 * @description 获取最近一次的填写记录
 * @description 获取最近一次的填写记录
 * @param {HttpOptions}[httpOptions] - 额外的查询配置
 * @returns {Promise<employeeLastDailyRS>} - 200 成功运营 successful operation
 */
export const getEmployeeLastDailyCollectionInfo = (httpOptions) => {
  return http.get("/tleave/mobile/collection/getEmployeeLastDailyCollectionInfo",
    {}, httpOptions);
};

/**
 * @typedef {object} GetEmployeeRecentDailyCollectionInfoStatusQuery - getEmployeeRecentDailyCollectionInfoStatus 查询构体
 * @property {string}[recentDayNumbers] - 天数
 */
/**
 * @description 获取最近几天的有效填写记录
 * @description 获取最近几天的有效填写记录
 * @param {GetEmployeeRecentDailyCollectionInfoStatusQuery} __query__ - 查询参数
 * @param {HttpOptions}[httpOptions] - 额外的查询配置
 * @returns {Promise<employeeRecentDailyRS>} - 200 成功运营 successful operation
 */
export const getEmployeeRecentDailyCollectionInfoStatus = (__query__, httpOptions) => {
  const { recentDayNumbers } = __query__ || {};
  return http.get(`/tleave/mobile/collection/getEmployeeRecentDailyCollectionInfoStatus?${stringify({ recentDayNumbers })}`,
    {}, httpOptions);
};

/**
 * @description 提交记录
 * @description 提交记录
 * @param {dailyCollectionInfoRecordDTO} body - 提交记录接口
 * @param {HttpOptions}[httpOptions] - 额外的查询配置
 * @returns {Promise<submitEmployeeDailyRS>} - 200 成功运营 successful operation
 */
export const submitEmployeeDailyCollectionInfo = (body, httpOptions) => {
  return http.post("/tleave/mobile/collection/submitEmployeeDailyCollectionInfo",
    body, httpOptions);
};

/**
 * @description 交记录V2
 * @description 提交记录V2
 * @param {dailyCollectionInfoRecordDTOV2} body - 交记录口V2 提交记录接口V2
 * @param {HttpOptions}[httpOptions] - 额外的查询配置
 * @returns {Promise<submitEmployeeDailyV2RS>} - 200 成功运营 successful operation
 */
export const submitEmployeeDailyCollectionInfoV2 = (body, httpOptions) => {
  return http.post("/tleave/mobile/collection/submitEmployeeDailyCollectionInfoV2",
    body, httpOptions);
};

/**
 * @typedef {object} GetLastDailyCollectionInfoRecordByEmployeeNumberByDateV2Query - getLastDailyCollectionInfoRecordByEmployeeNumberByDateV2 查询构体
 * @property {string}[dateFrom] - 开始时间
 * @property {string}dateTo - 结束时间
 */
/**
 * @description 获取指定日期内的填报记录
 * @description 获取指定日期内的填报记录
 * @param {GetLastDailyCollectionInfoRecordByEmployeeNumberByDateV2Query} __query__ - 查询参数
 * @param {HttpOptions}[httpOptions] - 额外的查询配置
 * @returns {Promise<employeeLastDailyV2RS>} - 200 成功运营 successful operation
 */
export const getLastDailyCollectionInfoRecordByEmployeeNumberByDateV2 = (__query__, httpOptions) => {
  const { dateFrom, dateTo } = __query__ || {};
  return http.get(`/tleave/mobile/collection/getLastDailyCollectionInfoRecordByEmployeeNumberByDateV2?${stringify({ dateFrom, dateTo })}`,
    {}, httpOptions);
};

/**
 * @typedef {object} GetEmployeeRecentDailyCollectionInfoStatusV2Query - getEmployeeRecentDailyCollectionInfoStatusV2 查询构体
 * @property {string}dateFrom - 开始时间
 * @property {string}dateTo - 结束时间
 */
/**
 * @description 获取指定日期内是否填报
 * @description 获取指定日期内是否填报
 * @param {GetEmployeeRecentDailyCollectionInfoStatusV2Query} __query__ - 查询参数
 * @param {HttpOptions}[httpOptions] - 额外的查询配置
 * @returns {Promise<employeeStatusDailyV2RS>} - 200 成功运营 successful operation
 */
export const getEmployeeRecentDailyCollectionInfoStatusV2 = (__query__, httpOptions) => {
  const { dateFrom, dateTo } = __query__ || {};
  return http.get(`/tleave/mobile/collection/getEmployeeRecentDailyCollectionInfoStatusV2?${stringify({ dateFrom, dateTo })}`,
    {}, httpOptions);
};

/**
 * @typedef {object} GetEmployeeRecentDailyCollectionEpidemicRiskQuery - getEmployeeRecentDailyCollectionEpidemicRisk 查询构体
 * @property {string}[riskLevel] - 风险等级
 * @property {string}[userName] - 员工姓名
 * @property {string}office - 部门
 */
/**
 * @description 获取中高风险地区员工信息
 * @description 获取中高风险地区员工信息
 * @param {GetEmployeeRecentDailyCollectionEpidemicRiskQuery} __query__ - 查询参数
 * @param {HttpOptions}[httpOptions] - 额外的查询配置
 * @returns {Promise<employeeRecentEpidemicRiskRS>} - 200 成功运营 successful operation
 */
export const getEmployeeRecentDailyCollectionEpidemicRisk = (__query__, httpOptions) => {
  const { riskLevel, office, userName } = __query__ || {};
  return http.get(`/tleave/mobile/collection/getEmployeeRecentDailyCollectionEpidemicRisk?${stringify({ riskLevel, office, userName })}`,
    {}, httpOptions);
};

/**
 * @description 获取中高风险地区统计信息
 * @description 获取中高风险地区统计信息
 * @param {HttpOptions}[httpOptions] - 额外的查询配置
 * @returns {Promise<employeeRecentRiskStatisticsRS>} - 200 成功运营 successful operation
 */
export const getEmployeeRecentDailyCollectionRiskStatistics = (httpOptions) => {
  return http.get("/tleave/mobile/collection/getEmployeeRecentDailyCollectionRiskStatistics",
    {}, httpOptions);
};
