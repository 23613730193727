/*
 * Author: bjiang
 * Create Time: 2019/10/10 11:14
 */
/**
 * @description 合并多个Ref
 * @param {Ref}refs
 * @returns {function(...[*]=)}
 */
export const invokeRef = (...refs) => (node) => {
  refs.filter(Boolean).forEach((ref) => {
    if (typeof ref === "function") {
      ref(node);
    } else if (Object.hasOwnProperty.call(ref, "current")) {
      ref.current = node;
    }
  });
};

/**
 * @description 验证是否为undefined
 * @param {*}arg
 * @returns {boolean}
 */
export const isUndefined = (arg) => typeof arg === "undefined";

/**
 * @description 空函数
 * @returns {undefined}
 */
export const noop = () => undefined;
