/*
 * Author: bjiang
 * Create Time: 2020/5/14 16:16
 */

import React from "react";
import { withRouterPropsTypes } from "../../helpers/commPropTypes";
import bg from "./bg.png";
import styles from "./styles.module.less";

/**
 * @description 系统建设中
 * @returns {Component}
 */
const Construction = ({ history }) => <div className={styles.body}>
  <img src={bg} alt={""}/>
  <span>攻城狮们正在加紧建设中</span>
  <button onClick={() => history.goBack()}>返回</button>
</div>;

Construction.propTypes = withRouterPropsTypes;

export default Construction;
