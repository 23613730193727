/*
 * Author: sh-home
 * Create Time: 2020/4/15 9:25
 */

export const NAME_SPACE = "USER_INFO_MANAGER";
// 开始登录
export const LOGIN_START = `${NAME_SPACE}/LOGIN_START`;
// 登录完成
export const LOGIN = `${NAME_SPACE}/LOGIN`;
// 退出登录
export const LOGOUT = `${NAME_SPACE}/LOGOUT`;
// 登录异常
export const LOGIN_FAIL = `${NAME_SPACE}/LOGIN_FAIL`;

export const UPDATE = `${NAME_SPACE}/UPDATE`;

// 清理本地数据
export const CLEAR = `${NAME_SPACE}/CLEAR`;
