/*
 * Author: sh-home
 * Create Time: 2020/4/15 9:25
 */

import { CLEAR, LOGIN, LOGIN_FAIL, LOGIN_START, NAME_SPACE, UPDATE } from "./constant";

const USER_SESSION_KEY = "USER-INFO";

const defaultState = {
  // 基于所有路由模块和autologin模块是同时加载的，logging应当初始值为true，否则autologin就无效了
  logging: true,
  isLogin: !!sessionStorage.getItem(USER_SESSION_KEY),
  user: JSON.parse(sessionStorage.getItem(USER_SESSION_KEY))
};

export default {
  [NAME_SPACE]: (state = defaultState, { type, ...action }) => {
    switch (type) {
      case LOGIN_START: {
        return { ...state, logging: true };
      }
      case LOGIN_FAIL: {
        return { ...state, logging: false, user: null, isLogin: false };
      }
      // 当前登录成功后存档用户TOKEN
      case LOGIN: {
        return { ...state, logging: false, isLogin: true };
      }
      case UPDATE : {
        sessionStorage.setItem(USER_SESSION_KEY, JSON.stringify(action.user));
        return { ...state, user: action.user };
      }
      case CLEAR: {
        return {
          logging: false,
          isLogin: false,
          user: {}
        };
      }
      default:
        return state;
    }
  }
};
