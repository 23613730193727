/*
 * Author: bjiang
 * Create Time: 2020/5/27 9:12
 */

import AllowConfig from "../../../helpers/AllowConfig";
import { MENU_TYPE } from "../../../helpers/DICT/define";
import { listMenu } from "../../interface/tleave/arrow/menu";
import { menuList } from "../../interface/tleave/sys";
import * as Action from "./action";
import { NAME_SPACE } from "./constant";

/**
 * @description 更新本地权限信息
 * @returns {function(...[*]=)}
 */
export const update = () => dispatch => {
  Promise.all([
    // 旧版菜单列表数据提取
    menuList().catch(() => ({ mobileMeuns: [] })).then(({ mobileMeuns: permit }) =>
      // 重置TYPE，使其与微服务TYPE保持一至
      permit.map(menuInfo => Object.assign(menuInfo, { type: MENU_TYPE[menuInfo.type] }))
    ),
    // 微服务功能菜单提取
    listMenu().catch(() => []).then((menuItem) => menuItem.map(
      ({ iconExpress, processDefineKey, scope, type, url, viewName }) => ({
        processDefineKey,
        scope,
        icon: iconExpress,
        label: viewName,
        url,
        type
      })))
  ]).then(
    ([permit, dynamics]) =>
      dispatch(Action.update(permit, dynamics))
  );
};

/**
 * @description 获取分组信息
 * @returns {function(function, function): Array<string>}
 */
export const groupBy = () => (dispatch, getState) => {
  const { permit, dynamics } = getState()[NAME_SPACE];
  return Array.from(new Set(permit.map(({ type }) => type).concat(dynamics.map(({ type }) => type))));
};

/**
 * @description 获取指定权限组列表
 * @param {string} type 分组类别
 * @returns {function(function, function): Array<PermitNode>}
 */
export const getMenusForType = (type) => (dispatch, getState) => {
  const { permit, dynamics } = getState()[NAME_SPACE];
  return permit.filter(({ type: t }) => t === type)
    .concat(
      dynamics.filter(({ type: t }) => t === type)
        .map(({ label, icon, processDefineKey, scope, url }) => ({
          href: url || `/${processDefineKey}/${scope}`.replace(/\/$/, ""),
          label,
          icon
        }))
    );
};

/**
 * @description 验证是否拥有访问权限
 * @param {string} href
 * @returns {function(*, *): boolean}
 */
export const isAllow = (href) => (dispatch, getState) => {
  const { permit } = getState()[NAME_SPACE];
  // 查找最符合href的匹配规则
  const [allow, path] = AllowConfig.reduce(([pAllow, pPath], [nAllow, nPath]) => (
    href.indexOf(nPath) === 0 && nPath.length > pPath.length
      ? [nAllow, nPath]
      : [pAllow, pPath]
  ), ["-", ""]);
  return allow === "+" ? true : permit.some(({ href: pHref }) => pHref === path);
};

/**
 * @description 清理本地权限规则
 * @returns {function(...[*]=)}
 */
export const clear = () => dispatch => {
  dispatch(Action.clear());
};
