/*
 * Author: sh-home
 * Create Time: 2020/4/15 9:24
 */

import { bool, func, object } from "prop-types";
import { connect } from "react-redux";
import { NAME_SPACE } from "./constant";
import { asyncUserInfo, autoLogin, login, logout } from "./helper";

/**
 * @typedef {object} UserState
 * @property {boolean} logging
 * @property {boolean} isLogin
 * @property {UserInfo} userIcon
 */

/**
 * 提取UserInfo状态
 * @param {UserState} state
 * @returns {UserState}
 */
const mapStateToProps = ({ [NAME_SPACE]: state }) => state;

const mapDispatchToProps = { login, logout, autoLogin, asyncUserInfo };

export default connect(mapStateToProps, mapDispatchToProps);

/**
 * @typedef {object} UserProps 用户状态
 * @property {boolean} logging 当前是否在登录中
 * @property {boolean} isLogin 登录状态
 * @property {object|null} user 用户信息
 * @property {function} login 登录方法
 * @property {function} logout 退出登录
 * @property {function} autoLogin 自动登录接口
 * @property {function} asyncUserInfo 同步用户信息
 */
export const propTypes = {
  logging: bool,
  isLogin: bool,
  user: object,
  login: func,
  logout: func,
  autoLogin: func,
  asyncUserInfo: func
};
