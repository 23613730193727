import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { createPortal } from "react-dom";

/**
 * @typedef {object} ExternalMountProps
 * @property {boolean}before
 * @property {Element}target
 * @property {string}[className]
 * @property {string}tag
 */
const propTypes = {
  // 是否前部加载入
  before: PropTypes.bool,
  // 挂载位置
  target: PropTypes.instanceOf(window.Element),
  // 根模块附加样式
  className: PropTypes.string,
  // 标签
  tag: PropTypes.string
};
const defaultProps = {
  before: false,
  target: document.body,
  tag: "div"
};

/**
 * @param {ExternalMountProps}props 外部状态
 * @returns {*}
 */
function ExternalMount (props) {
  const { children, before, target, className, tag } = props;
  const [mount, setMount] = useState(false);
  const [container] = useState(document.createElement(tag));
  useEffect(() => {
    if (className) container.className = className;
    if (before && target.children.length > 0) {
      target.insertBefore(container, target.children[0]);
    } else {
      target.appendChild(container);
    }
    setMount(true);
    return () => {
      target.removeChild(container);
    };
  }, []);
  return mount && createPortal(children, container);
}

ExternalMount.propTypes = propTypes;
ExternalMount.defaultProps = defaultProps;

export default ExternalMount;
