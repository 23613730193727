/*
  name: 对应form表单中values的对应字段
  type: 对应该问题需要引用的组件
  label: 为问题描述
  optional: 部分问题为下拉框，此为下拉选项
 */
export default [
  {
    name: "isolateReason",
    type: "radio",
    label: "类别情况",
    options: [
      { value: "1", label: "本人14天内去过定点医院或发热门诊看病或陪同看病" },
      { value: "2", label: "共同居住人14天内从境外返回" }
    ]
  },
  {
    label: "具体说明",
    name: "isolateRemark",
    type: "input"
  }
];
