/*
 * Author: bjiang
 * Create Time: 2020/5/29 8:45
 */

import { getter } from "property-expr";

/**
 * @typedef {object} DictConfig
 * @property {function({type?:string,key?:string}): Promise<Array<dictListRS>>} [getDb] 查询方法
 */
const config = {
  getDb: () => Promise.resolve([])
};

const cacheMap = new Map();
const loading = {};

/**
 * @description 更新cacheMap
 * @param {Array<dictListRS>} list
 */
const updateMap = (list) => {
  list.forEach(({ key, type, label }) => {
    let typeMap = cacheMap.get(type);
    if (!typeMap) {
      typeMap = new Map();
      cacheMap.set(type, typeMap);
    }
    typeMap.set(key, label);
  });
};

/**
 * @description 从cacheMap中提取对应值
 * @param {string}type
 * @param {string}key
 * @returns {Array<{value:string,label:string}>}
 */
const getLabelsFromCache = (type, key) => {
  const typeMap = cacheMap.get(type);
  if (!typeMap) throw new Error(`本地尚未缓存到${type}信息`);
  if (loading[type]) delete loading[type];
  if (key !== undefined) {
    const label = typeMap.get(key);
    if (!label) throw new Error(`从本地${type}缓存中没有找到${key}对应的信息`);
    return [{ value: key, label }];
  } else {
    return Array.from(typeMap).map(([value, label]) => ({ value, label }));
  }
};

/**
 * @typedef {object} LVObj
 * @property {string} [value]
 * @property {string} [label]
 */

/**
 * @typedef {object} DictQuery
 * @property {string} [type]
 * @property {string} [key]
 */

/**
 * @description 通过type与Key值获取对应的字段
 * @param {DictQuery} query
 * @returns {Promise<Array<LVObj>>}
 */
const dict = ({ type, key }) => {
  return new Promise(resolve => resolve(getLabelsFromCache(type, key)))
    .catch(() => {
      loading[type] = loading[type] || config.getDb({ type }).then(updateMap);
      return loading[type].then(() => getLabelsFromCache(type, key));
    });
};

export default dict;

/**
 * @description 设置默认配置项
 * @param {DictConfig}conf
 * @returns {any}
 */
export const setConfig = (conf) => Object.assign(config, conf);
/**
 * @typedef {Object} LocalDict
 * @property {string} label 标识
 * @property {*} value 值
 */

/**
 * @typedef {Object} DataDictExtConf
 * @property {string} label 标题
 * @property {string} name 字段名
 * @property {string} [after] 字段名
 * @property {string} [dictKey] 数据字典Type
 * @property {Array<LocalDict>} [localMap] 本地数据
 */

/**
 * @description 获取数据 从values数据表中提conf.name的值，并与数字字典或本地Map进行合并，以得到具体数值对象
 * @param {Object} values
 * @param {DataDictExtConf} conf
 * @returns {Promise<{ value:string, label:string, name:string }>}
 */
export const getValueOf = async (values, conf) => {
  const { name, label, dictKey, localMap, after } = conf;
  let value = getter(name)(values);
  if (dictKey) {
    const [result] = await dict({ type: dictKey, key: value });
    value = result ? result.label : value;
  } else if (localMap && Array.isArray(localMap)) {
    value = localMap.reduce(
      (target, { value: currentValue, label: currentLabel }) => (
        currentValue === value ? currentLabel : target
      ), value);
  } else if (localMap) {
    value = localMap[value] || value;
  }
  return { value, label, name, after };
};
