/*
 * Author: bjiang
 * Create Time: 2020/5/27 9:12
 */

import { CLEAR, NAME_SPACE, UPDATE } from "./constant";

const LOCAL_AUTHORITY = "LOCAL_AUTHORITY";

const defaultState = {
  permit: [],
  dynamics: [],
  isSync: false
};

const localState = JSON.parse(sessionStorage.getItem(LOCAL_AUTHORITY));

export default {
  [NAME_SPACE]: (state = localState || defaultState, { type, ...action }) => {
    switch (type) {
      case UPDATE: {
        const newState = { ...state, permit: action.permit, dynamics: action.dynamics, isSync: true };
        sessionStorage.setItem(LOCAL_AUTHORITY, JSON.stringify(newState));
        return newState;
      }
      case CLEAR: {
        sessionStorage.removeItem(LOCAL_AUTHORITY);
        return defaultState;
      }
      default: {
        return state;
      }
    }
  }
};
