/*
 * Author: bjiang
 * Create Time: 2019/11/18 9:54
 */

/**
 * @typedef {object} LoadingLocal
 * @property {string} text 加载内容时的提示文本
 * @property {string} img 加载内容时的Loading图标
 */

/**
 * Loading组件本地信息
 * @type {LoadingLocal}
 */
const local = {
  text: "正在加载，请耐心等待...",
  img: require("./loading.svg")
};

/**
 * 设置组件语言信息
 * @param {LoadingLocal}_local
 * @returns {LoadingLocal}
 */
export const setDefaultLocal = (_local) => Object.assign(local, _local);

/**
 * 获取Loading组件语言信息
 * @returns {LoadingLocal}
 */
export const getDefaultLocal = () => local;
