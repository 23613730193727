/*
 * Author: bjiang
 * Create Time: 2020/5/26 17:24
 */

// 招待活动内容
export const RECEPTION_EVENT = "oa_reception_event_type";

export const ENTRANCE_TYPE = {
  NORMAL: "个人办公",
  MANAGER: "办公管理"
};

export const USER_SCOPE = {
  NORMAL: "个人办公",
  MANAGE: "办公管理"
};

export const MENU_TYPE = {
  0: "NORMAL",
  1: "MANAGER"
};

// 出差事由
export const BUSINESS_TRIP_REASONS = "oa_bussiness_type";

// 请假类别
export const LEAVE_TYPE = "oa_leave_type";

// 录用
// 学历
export const EMPLOY_EDUCATIONAL_TYPE = "employ_educational_type";
// 职级
export const EMPLOY_CLASSOFPOSITIONS_TYPE = "employ_classOfPositions_type";
// 岗位
export const EMPLOY_STATION_TYPE = "employ_station_type";
// 拟录用部门
export const EMPLOY_OFFICE_TYPE = "employ_office_type";
// 婚姻状况
export const EMPLOY_MARITALSTATUS_TYPE = "employ_maritalStatus_type";
// 性别
export const EMPLOY_SEX_TYPE = "employ_sex_type";
// 应聘者政治面貌
export const EMPLOY_POLITICCOUNTENANCE_TYPE = "employ_politicCountenance_type";
// 信息采集，中高风险地区监控
// 本人或周边人员当天是否在生病
// export const IF_SELF_OR_AROUND_ILLNESS_TODAY = { 0: "未生病", 1: "生病" };
export const IF_SELF_OR_AROUND_ILLNESS_TODAY = [{ value: 0, label: "未生病" }, { value: 1, label: "生病" }];
// 是否认定疑似或确认感染新冠病毒
export const IF_COVID_19 = { 0: "否", 1: "确认", 2: "疑似" };
// 是否直接或间接接触过疑似或确认人员
export const IF_ATTACH_COVID_19 = { 0: "否", 1: "直接接触", 2: "间接接触" };
// 是否隔离
export const IF_ISOLATE = { 0: "否", 1: "隔离" };
// 风险等级
export const RISK_GRADE = { 0: "中高风险", 1: "持续跟踪", 2: "解除跟踪" };
// 是否离开
export const IS_LEAVE = { 0: "未离开高风险地区", 1: "离开高风险地区" };
