/*
 * Author: bjiang
 * Create Time: 2020/1/10 9:44
 */

/**
 * @typedef {Object} ApiErrors
 * @property {string} message
 * @property {string} [code]
 * @property {string} [devMessage]
 */
const DEFAULT_API_ERRORS = [{
  message: "发生未知异常！",
  code: "UI-00-0000",
  devMessage: "发生未知异常！"
}];

/**
 * @typedef {Object} HTTPConfig
 * @property {Array<ApiErrors>} [DEFAULT_API_ERRORS]
 * @property {Object} [headers]
 * @property {function} [getToken]
 * @property {function} [clearUser]
 * @property {function|boolean} [retry]
 */
/**
 * @type HTTPConfig
 */
export const CONFIG = {
  DEFAULT_API_ERRORS,
  headers: {
    // 语言
    "Accept-Language": "zh-CN"
  },
  getToken: () => undefined,
  clearUser: () => undefined,
  retry: false
};

/**
 * @param {HTTPConfig}options
 * @returns {HTTPConfig}
 */
export const setOptions = (options) => Object.assign(CONFIG, options);

export const filter = {
  before: [],
  after: [
    // 超时任务解析
    (status, data) => { if (status === "TIMEOUT") throw data; },
    // 重置异常信息到apiErrors
    (status, data) => data || CONFIG.DEFAULT_API_ERRORS
  ]
};

export const after = {
  add: (fn) => filter.after.push(fn) - 1,
  remove: (fn) => {
    if (typeof fn === "number") filter.after.splice(fn, 1);
    else filter.after = filter.after.filter((f) => f !== fn);
  }
};
export const before = {
  add: (fn) => filter.before.push(fn) - 1,
  remove: (fn) => {
    if (typeof fn === "number") filter.before.splice(fn, 1);
    else filter.before = filter.before.filter((f) => f !== fn);
  }
};
