/*
 * Author: bjiang
 * Create Time: 2020/5/27 9:12
 */

export const NAME_SPACE = "AUTHORITY";

// 更新本地权限配置
export const UPDATE = `${NAME_SPACE}/UPDATE`;
// 清空当前用户权限组
export const CLEAR = `${NAME_SPACE}/CLEAR`;
