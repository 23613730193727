/**
 * Created by chaozhang on 2019/9/27.
 */

import React from "react";
import ReactDom from "react-dom";
import Loading from "./loadingComponent";
import { setDefaultLocal } from "./local";
import styles from "./styles.module.less";

/**
 * 使页面显示Loading
 * @param {object}[props] loading props
 * @returns {_Loading} //
 */
function show (props) {
  const loading = new _Loading();
  loading.show(props);
  return loading;
}

/**
 * @description Loading组件
 */
class _Loading {
  /**
   * @constructor
   */
  constructor () {
    this.ref = React.createRef();
  }

  /**
   * @description 显示loading
   * @param {function}onHidden
   * @param {object}style
   * @param {object}props
   */
  show ({ onHidden, style = styles, ...props } = {}) {
    const div = document.createElement("div");
    div.className = style["loading-container"];
    document.body.appendChild(div);
    ReactDom.render(<Loading ref={this.ref}
      showMask type={"popup"} style={style} {...props} external={false}
      onHidden={() => {
        if (!document.body.contains(div)) return;
        ReactDom.unmountComponentAtNode(div);
        document.body.removeChild(div);
        if (onHidden) onHidden();
      }}/>, div);
  }

  /**
   * @description 关闭Loading
   */
  hide () {
    this.ref.current.props.onHidden();
  }
}

Loading.show = show;
Loading.setDefaultLocal = setDefaultLocal;
export default Loading;
export { setDefaultLocal, show };
window.Loading = Loading;
