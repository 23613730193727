import PropTypes from "prop-types";
import React from "react";
import { Redirect, Route, withRouter } from "react-router-dom";
import connect, { propTypes as UserPropTypes } from "src/services/profile/User";
import Loading from "src/ui/loading";

const IS_LOGIN = "已登录";
const IS_LOGIN_ING = "登录中";
const UN_LOGIN = "未登录";

/**
 * @typedef {UserProps}AuthorizedRouteProps
 * @property {string} path
 * @property {boolean} exact
 * @property {function} component
 */

const propTypes = {
  ...UserPropTypes,
  path: PropTypes.string.isRequired,
  exact: PropTypes.bool,
  strict: PropTypes.bool,
  component: PropTypes.elementType.isRequired
};

/**
 * @description 登录拦截模块
 */
class PrivateRoute extends React.Component {
  /**
   * @constructor
   * @param {AuthorizedRouteProps}props
   */
  constructor (props) {
    super(props);
    this.state = { status: null };
  }

  /**
   * @description 生命周期函数
   * @param {AuthorizedRouteProps}props
   * @returns {{status: string}}
   */
  static getDerivedStateFromProps (props) {
    const { isLogin, logging } = props;
    if (logging) {
      return { status: IS_LOGIN_ING };
    } else if (isLogin) {
      return { status: IS_LOGIN };
    } else {
      return { status: UN_LOGIN };
    }
  }

  /**
   * @returns {Component}
   */
  render () {
    const { state: { status }, props } = this;
    switch (status) {
      case IS_LOGIN:
        return <Route {...props} />;
      case IS_LOGIN_ING:
        return <Loading isShow={true}/>;
      case UN_LOGIN:
        return <Redirect to={"/login"}/>;
      default:
        return null;
    }
  }
}

PrivateRoute.propTypes = propTypes;

export default withRouter(connect(PrivateRoute));
