/*
 * Author: Tlionsir
 * Create Time: 2020/5/13 11:16
 */

import React from "react";
import { Route, Switch } from "react-router-dom";
import loadable from "../business/loadable";
import { withRouterPropsTypes } from "../helpers/commPropTypes";

const List = loadable(() => import(/* webpackChunkName: "Hospitality/List" */"src/scene/Hospitality/List"));
const Manager = loadable(() => import(/* webpackChunkName: "Hospitality/Manager" */"src/scene/Hospitality/List/Manager"));
const Apply = loadable(() => import(/* webpackChunkName: "Hospitality/Apply" */"src/scene/Hospitality/Apply"));
const Detail = loadable(() => import(/* webpackChunkName: "Hospitality/detail" */"src/scene/Hospitality/Detail"));
/**
 * @description 招待模块
 * @param {string}path
 * @returns {ReactElement}
 * @constructor
 */
const HospitalityRouter = ({ match: { path } }) => (
  <Switch>
    {/* 个人列表 */}
    <Route exact path={`${path}`} component={List}/>
    {/* 申请 */}
    <Route exact path={`${path}/apply`} component={Apply}/>
    {/* <Route exact path={`${path}/apply/:type`} component={Noop}/> */}
    {/* 管理列表 */}
    <Route exact path={`${path}/manager`} component={Manager}/>
    {/* 管理详情 */}
    <Route path={`${path}/manager/:id`} component={Detail}/>
    {/* 详情 */}
    <Route path={`${path}/:id`} component={Detail}/>
  </Switch>
);

HospitalityRouter.propTypes = withRouterPropsTypes;

export default HospitalityRouter;
