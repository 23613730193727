/*
 * Author: bjiang
 * Create Time: 2020/5/7 15:23
 */
import React from "react";
import ReactDom from "react-dom";
import styles from "./styles.module.less";
import Tip from "./Tip";

/**
 * @typedef {Object} ToastProps
 * @property {string} [className]
 * @property {ReactNode|string} message
 * @property {number} [duration]
 * @property {boolean} [checkOver]
 * @property {boolean} [mask]
 * @property {function} [onHide]
 */

/**
 * @description 轻提示
 */
class Toast {
  /**
   * @constructor
   */
  constructor () {
    this.ref = React.createRef();
    this.onHide = null;
    this.handleHide = this.handleHide.bind(this);
  }

  /**
   * @description 清理回收组件
   */
  handleHide () {
    const dom = this.dom;
    if (!document.body.contains(dom)) return;
    ReactDom.unmountComponentAtNode(dom);
    document.body.removeChild(dom);
    typeof this.onHide === "function" && this.onHide();
  }

  /**
   * @description
   * @param {ToastProps} props
   */
  _show (props) {
    this.onHide = props.onHide;
    const dom = document.createElement("div");
    dom.classList.add(styles.toast);
    document.body.appendChild(dom);
    this.dom = dom;
    ReactDom.render(<Tip styles={styles}
      {...props}
      ref={this.ref}
      onHide={this.handleHide}
    />, dom);
  }

  /**
   * @description 展开提示
   * @param {ToastProps} props
   * @returns {Toast}
   */
  static show (props) {
    const toast = new Toast();
    toast._show(props);
    return toast;
  }

  /**
   * @description 关闭隐藏组件
   */
  hide () {
    this.ref.current && this.ref.current.hide();
  }
}

export default Toast;
