/*
 * Author: sh-home
 * Create Time: 2020/3/27 14:32
 */
import PropTypes from "prop-types";

const { bool, func, number, string, object } = PropTypes;

/**
 * @typedef {object} location
 * @property {string} pathname
 * @property {string} search
 * @property {string} hash
 * @property {object} state
 */
const location = PropTypes.shape({
  pathname: string,
  search: string,
  hash: string,
  state: object
});

/**
 * @typedef {object} history
 * @property {number} length
 * @property {string} action
 * @property {location} location
 * @property {function} push
 * @property {function} replace
 * @property {function} go
 * @property {function} goBack
 * @property {function} goForward
 * @property {function} block
 */
const history = PropTypes.shape({
  length: number,
  action: string,
  location,
  push: func,
  replace: func,
  go: func,
  goBack: func,
  goForward: func,
  block: func
});

/**
 * @typedef {object} match
 * @property {object} params 参数
 * @property {boolean} isExact
 * @property {string} path
 * @property {string} url
 */

/**
 * @typedef {object} withRouterProps
 * @property {history} history
 * @property {location} location
 * @property {match} match
 */
export const withRouterPropsTypes = {
  history,
  location,
  match: PropTypes.shape({
    params: object,
    isExact: bool,
    path: string,
    url: string
  })
};
